const AdminRoutes = {
  Admin: '/admin',
  Dashboard: '/dashboard',
  User: '/user',
};

const UserRoutes = {
  Calculator: '/calculator',
};

const Routes = {
  Login: '/login',
  ForgotPassword: '/forgotpassword',
  EditAccount: '/account/edit',
  ...AdminRoutes,
  ...UserRoutes,
};

export default Routes;
