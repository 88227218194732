import styled from 'styled-components';

const StyledTermsCondition = styled.div`
   {
    margin-right: 6%;
    max-width: 880px;
  }

  .termsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .termsWrapper p {
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
  }

  .termsHeader {
    text-align: center;
    padding-bottom: 26px;
  }

  .termsForm {
    display: flex;
    justify-content: space-around;
    padding: 25px;
  }

  .termsForm button {
    padding: 18px 40px;
  }

  @media screen and (max-width: 1070px) {
     {
      margin: 0 auto;
      padding: 0 70px;
      max-width: 1000px;
    }
  }
`;
export default StyledTermsCondition;
