import styled from 'styled-components';

const StyledDashboardUserList = styled.div<any>`
  .dashboardTableSection {
    background-color: ${props => props.theme.main.colors.colorWhite};
    border: 1px solid ${props => props.theme.main.colors.colorWhite};
    border-radius: 4px;
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  .modalWindow {
    right: calc(50% - 200px);
    position: fixed;
    top: 35%;
    z-index: 100;
    width: 400px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .dashboardTableContainer thead tr {
    text-align: left;
  }

  .dashboardTableContainer thead,
  .dashboardTableContainer tr {
    border-bottom: 1px solid #eaeaea;
  }

  .dashboardTableContainer tr:last-child {
    border-bottom: none;
  }

  .dashboardTableContainer th:first-child,
  .dashboardTableContainer td:first-child {
    padding: 20px;
    width: 57px;
  }

  .dashboardTableContainer th:nth-of-type(2),
  .dashboardTableContainer td:nth-of-type(2) {
    width: 24%;
    vertical-align: middle;
  }

  .dashboardTableContainer th {
    padding-left: 0;
    vertical-align: middle;
  }

  .dashboardTableContainer a:hover {
    text-decoration: none;
  }

  .accountUserLink {
    display: flex;
    align-items: center;
  }

  .accountUserLink:hover {
    color: #4277f8;
    text-decoration: none;
  }

  .accountUserLink svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .dashManageAccount {
    text-align: end;
  }

  .dashManageAccount a,
  .dashManageAccount button {
    position: relative;
    background: none;
    margin-right: 20px;
  }

  .dashManageAccount a:hover path,
  .dashManageAccount button:hover path {
    fill: ${props => props.theme.main.colors.colorBlueMain};
  }

  .tooltip {
    display: none;
  }

  .dashManageAccount a:hover .tooltip,
  .dashManageAccount button:hover .tooltip {
    position: absolute;
    display: inherit;
    bottom: 28px;
    right: -18px;
    font-size: 14px;
    line-height: 1.14;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    color: ${props => props.theme.main.colors.colorWhite};
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    border-radius: 4px;
    padding: 2px 15px;
  }

  .dashManageAccount button:hover .tooltip {
    bottom: 28px;
    right: -22px;
  }

  .dashboardTableContainer {
    width: 100%;
  }

  .dashboardTableContainer th button {
    position: relative;
    display: flex;
  }

  .dashboardTableContainer th button:hover .tooltip {
    position: absolute;
    display: inherit;
    bottom: 25px;
    left: -22px;
    font-size: 14px;
    line-height: 1.14;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    color: ${props => props.theme.main.colors.colorWhite};
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    border-radius: 4px;
    padding: 2px 15px;
  }

  .sortIconsBlock {
    margin-right: 10px;
  }

  .sortIconsBlock svg {
    width: 10px;
  }

  .dashboardTableSection input {
    position: relative;
  }

  .dashboardCheckbox {
    visibility: hidden;
  }

  .dashboardCheckbox[type='checkbox']:checked:before,
  .dashboardCheckbox[type='checkbox']:not(:checked):before {
    content: '';
    position: absolute;
    left: 0px;
    top: -2px;
    height: 16px;
    width: 16px;
    border: 1px solid ${props => props.theme.main.colors.colorBorderCheckbox};
    border-radius: 3px;
    background-color: ${props => props.theme.main.colors.colorWhite};
    box-shadow: 0 5px 9px -6px rgba(0, 0, 0, 0.1);
  }

  .dashboardCheckbox[type='checkbox']:checked:before {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    border-color: ${props => props.theme.main.colors.colorBlueMain};
    background-position: 0 2px;
    background-repeat: no-repeat;
  }

  .dashboardCheckbox[type='checkbox']:hover:before {
    border-color: ${props => props.theme.main.colors.colorBlueMain};
  }

  .dashboardCheckbox[type='checkbox']:checked:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 2px;
    width: 10px;
    height: 5px;
    border-radius: 1px;
    border-left: 2px solid ${props => props.theme.main.colors.colorWhite};
    border-bottom: 2px solid ${props => props.theme.main.colors.colorWhite};
    transform: rotate(-45deg);
  }
`;
export default StyledDashboardUserList;
