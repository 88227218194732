import React, { ReactChild } from 'react';
import { Logo } from 'src/components';
import AuthBg from 'src/assets/img';
import StyledLoginLayout from './loginLayoutStyles';

type Props = { children: ReactChild };

const LoginLayout = ({ children }: Props) => {
  return (
    <StyledLoginLayout>
      <div className="loginFormContainer">
        <div className="logoSection">
          <Logo />
        </div>
        <div className="inputSection">{children}</div>
      </div>
      <div className="loginPageContainer">
        <img src={AuthBg} alt="" />
      </div>
    </StyledLoginLayout>
  );
};

export default LoginLayout;
