import React from 'react';
import { BtnPrimary } from 'src/components';
import StyledModalWindow from './ModalWindowStyles';

interface DeleteUserModalProps {
  setIsModalShow: (e: boolean) => void;
  clearPage?: () => void;
  text: string;
  action: () => void;
}

const ModalWindow = (props: DeleteUserModalProps) => {
  const { setIsModalShow, text, action } = props;

  return (
    <StyledModalWindow>
      <div className="delUserWrapper">
        <p className="delUserHeader">{text}</p>
        <div className="delUserForm">
          <BtnPrimary
            type="button"
            btnText="NO"
            size="tiny"
            action={() => setIsModalShow(false)}
          />
          <BtnPrimary type="submit" btnText="YES" size="tiny" action={action} />
        </div>
      </div>
    </StyledModalWindow>
  );
};

export default ModalWindow;
