import React from 'react';
import { Edit, Delete } from 'src/assets/icons';
import { CalculateType } from 'src/actions/calculate';
import {
  inputsConfig,
  SizeInput,
  RadioInput,
  calculationTypeConfig,
} from 'src/config/calculationConfig';

type CollapsedCalcSectionType = {
  calculation: CalculateType;
  index: number;
  editCalculation: (index: number) => void;
  deleteCalculation: (index: number) => void;
};

const CollapsedCalcSection = (props: CollapsedCalcSectionType) => {
  const { calculation, index, editCalculation, deleteCalculation } = props;
  const renderCalculationInfo = (
    data: CalculateType,
    item: SizeInput | RadioInput,
    i: number
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    let value = data[item.name];
    if (item.name === 'black') {
      value = value === 1 ? 'Black' : 'Clear';
    }
    if (item.name === 'reinforce') {
      value = value === 1 ? 'Yes' : 'No';
    }
    if ((item.name === 'length' || item.name === 'price') && !value) {
      value = 1;
    }
    return (
      <li key={i}>
        <span>{`${item.label}: ${value}`}</span>
      </li>
    );
  };
  const typeLabel = calculationTypeConfig.find(
    item => item.value === calculation.calculation_type
  );

  return (
    <div className="colSection collapsedCalcSection">
      <div className="row">
        <div className="rowHeading">
          <h3>{`Calculation №:${index + 1}`}</h3>
        </div>
        <div className="rowActions">
          <button
            className="rowActionsButton"
            type="button"
            onClick={() => editCalculation(index)}
          >
            <Edit />
            <span className="tooltip">Edit</span>
          </button>
          <button
            className="rowActionsButton"
            type="button"
            onClick={() => deleteCalculation(index)}
          >
            <Delete />
            <span className="tooltip">Delete</span>
          </button>
        </div>
      </div>
      <div className="collapsedInfo">
        <ul>
          <li>
            <span>{`Type: ${typeLabel && typeLabel.label}`}</span>
          </li>
          {inputsConfig[calculation.calculation_type].map((item, i: number) => {
            return renderCalculationInfo(calculation, item, i);
          })}
        </ul>
      </div>
    </div>
  );
};

export default CollapsedCalcSection;
