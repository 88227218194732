import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Heading, Input, BtnPrimary } from 'src/components';
import { login } from 'src/actions';
import Routes from 'src/config/routes';
import StyledLoginForm from './loginFormStyles';

interface ValuesTypes {
  userName: string;
  password: string;
}
interface LoginFormProps {
  setIsShowing: (e: boolean) => void;
  setIsLoading: (e: boolean) => void;
  isLoading: boolean;
}
const LoginForm = (props: LoginFormProps) => {
  const { setIsShowing, setIsLoading, isLoading } = props;
  const [isError, setErrorMessage] = useState(false);
  const [isValidationErr, setValidationErr] = useState(false);
  const onSubmitLogin = (values: ValuesTypes) => {
    setIsLoading(true);
    login(
      values.userName,
      values.password,
      setIsLoading,
      setErrorMessage,
      setValidationErr,
      setIsShowing
    );
  };
  return (
    <StyledLoginForm>
      <div className="wrapper">
        <Heading
          text="Welcome back! Please login to your account"
          heading="Login"
          isValidationErr={isValidationErr}
          isError={isError}
        />
        <Form
          onSubmit={onSubmitLogin}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="formRow">
                  <div className="col-12">
                    <span className="inputLabel">Username</span>
                    <Input
                      type="text"
                      placeholder="Write username here"
                      name="userName"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="col-12">
                    <span className="inputLabel">Password</span>
                    <Input
                      type="password"
                      placeholder="Write password here"
                      name="password"
                    />
                  </div>
                </div>
                <div className="formRow termsAndPass">
                  <div className="col-6">
                    <Link className="forgotPass" to={Routes.ForgotPassword}>
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>
              <div className="formAction">
                {isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="#4277F8"
                    height={50}
                    width={50}
                    className="loader"
                  />
                ) : (
                  <BtnPrimary type="submit" btnText="Login" />
                )}
              </div>
            </form>
          )}
        />
      </div>
    </StyledLoginForm>
  );
};

export default LoginForm;
