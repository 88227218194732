import React from 'react';

const Logout = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-489.000000, -3955.000000)">
          <g
            id="Icon/Filled/Logout"
            transform="translate(486.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M19.7284146,11.410935 L13.589065,5.27144715 C13.413813,5.09619512 13.1802358,5 12.9311789,5 C12.6818455,5 12.4484065,5.09633333 12.2731545,5.27144715 L11.715748,5.82899187 C11.5406341,6.00396748 11.4441626,6.23768293 11.4441626,6.48687805 C11.4441626,6.73593496 11.5406341,6.97752846 11.715748,7.15250407 L15.2973577,10.7419919 L3.91841463,10.7419919 C3.40537398,10.7419919 3,11.1436341 3,11.656813 L3,12.4450325 C3,12.9582114 3.40537398,13.4003496 3.91841463,13.4003496 L15.3379919,13.4003496 L11.7158862,17.009878 C11.5407724,17.1851301 11.4443008,17.4124878 11.4443008,17.6616829 C11.4443008,17.9106016 11.5407724,18.1412764 11.7158862,18.3163902 L12.2732927,18.8721382 C12.4485447,19.0473902 12.6819837,19.1428943 12.9313171,19.1428943 C13.180374,19.1428943 13.4139512,19.0461463 13.5892033,18.8708943 L19.7285528,12.7315447 C19.9042195,12.5557398 20.0008293,12.3210569 20.0001419,12.0715854 C20.0006911,11.8212846 19.9042195,11.5864634 19.7284146,11.410935 Z"
                id="Path"
                fill="#B6B7C3"
                fillRule="nonzero"
                transform="translate(11.500071, 12.071447) rotate(180.000000) translate(-11.500071, -12.071447) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logout;
