import React from 'react';

const SortDown = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon/Filled/Sort/Down"
          transform="translate(-4.000000, 0.000000)"
        >
          <g>
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <path
              d="M13.2570683,19.8197094 L13.2570683,0 L10.7429317,0 L10.7429317,19.8197094 L5.77757317,14.9829996 L4,16.7145172 L11.1112134,23.6414844 C11.6022557,24.1195052 12.3977443,24.1195052 12.8887866,23.6414844 L20,16.7145172 L18.2224268,14.9829996 L13.2570683,19.8197094 Z"
              id="Path"
              fill="#4277F8"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SortDown;
