import { getSessionID } from 'src/services/user';
import errMessage from 'src/helpers/errorMessage';
import http from 'src/services/http';

export type CalculateType = {
  calculation_type: string;
  size_mm?: string;
  size_dn?: string;
  thickness?: string;
  size_b?: string;
  thickness_b?: string;
  size_s?: string;
  thickness_s?: string;
  reinforce?: number;
  black?: number;
  dn1?: string;
  dn2?: string;
};

export type CalculateResponseType = {
  ajh: string;
  message: string;
  price: string;
  total_length: string;
  total_ajh: string;
  total_price: string;
};

type CallBackType = (
  flag: boolean,
  result?: CalculateType & CalculateResponseType,
  fromData?: CalculateType
) => void;

const calculate = async (request: CalculateType, callBack: CallBackType) => {
  try {
    await callBack(true);
    const sessionID = await getSessionID();
    const response: CalculateResponseType = await http(
      `calculate/${sessionID}`,
      {
        method: 'POST',
        body: { ...request },
      }
    );
    if (response && response.message === 'ok') {
      await callBack(false, { ...request, ...response }, request);
    }
  } catch (e) {
    errMessage(e);
  }
};

export default calculate;
