import React from 'react';
import { BtnPrimary } from 'src/components';
import { useAuth } from 'src/context/auth';
import Routes from 'src/config/routes';
import { useHistory } from 'react-router';
import { modifyUser } from 'src/actions';
import StyledTermsConditions from './TermsConditionsStyles';

interface TermsConditionsProps {
  setIsShowing: (e: boolean) => void;
  setIsLoading: (e: boolean) => void;
}

const TermsConditions = (props: TermsConditionsProps) => {
  const { setIsShowing, setIsLoading } = props;
  const context = useAuth();
  const history = useHistory();

  const handleSendingForm = (flag: boolean) => {
    setIsLoading(flag);
    history.push(Routes.Dashboard);
  };
  const onDecline = () => {
    setIsLoading(false);
    setIsShowing(false);
  };
  const onAccept = () => {
    if (context) {
      const { userData, userPass }: any = context;
      const { _key, _id, _rev, valid, ...modifiedUser } = userData;
      // eslint-disable-next-line @typescript-eslint/camelcase
      modifiedUser.valid_to = valid.to;
      modifiedUser.privileges = JSON.stringify(modifiedUser.privileges);
      // eslint-disable-next-line @typescript-eslint/camelcase
      modifiedUser.valid_from = valid.from;
      modifiedUser.password = userPass;
      // eslint-disable-next-line @typescript-eslint/camelcase
      modifiedUser.terms_conditions = 1;
      modifyUser(modifiedUser, handleSendingForm);
    }
  };
  return (
    <StyledTermsConditions>
      <div className="termsWrapper">
        <h1 className="termsHeader">Terms&Conditions</h1>
        <p>
          Terms and Conditions agreements act as a legal contract between you
          (the company) who has the website or mobile app and the user who
          access your website and mobile app. Having a Terms and Conditions
          agreement is completely optional. No laws require you to have one. Not
          even the super-strict and wide-reaching General Data Protection
          Regulation (GDPR).
        </p>
        <div className="termsForm">
          <BtnPrimary
            type="button"
            btnText="DECLINE"
            size="small"
            action={onDecline}
          />
          <BtnPrimary
            type="submit"
            btnText="ACCEPT"
            size="small"
            action={onAccept}
          />
        </div>
      </div>
    </StyledTermsConditions>
  );
};

export default TermsConditions;
