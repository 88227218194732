import React from 'react';

const SortUp = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon/Filled/Sort/Up" transform="translate(-4.000000, 0.000000)">
          <g>
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <path
              d="M20,7.28555056 L12.8887866,0.358518965 C12.3977443,-0.119506322 11.6022557,-0.119506322 11.1112134,0.358518965 L4,7.28555056 L5.77757317,9.01708424 L10.7429317,4.18032949 L10.7429317,24 L13.2570683,24 L13.2570683,4.18032949 L18.2224268,9.01708424 L20,7.28555056 Z"
              id="Path"
              fill="#4277F8"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SortUp;
