import { forgotPass } from 'src/services/user';
import errMessage from 'src/helpers/errorMessage';

interface ResponseTypes {
  message: string;
}

const forgotPassword = async (
  userName: string,
  callBlack: (e: boolean) => void,
  setPassMessage: (message: string) => void
) => {
  try {
    const { message }: ResponseTypes = await forgotPass(userName);
    if (message) {
      setPassMessage(message);
      callBlack(false);
    }
  } catch (e) {
    errMessage(e);
    callBlack(false);
  }
};

export default forgotPassword;
