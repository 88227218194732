import React from 'react';

const Plus = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-549.000000, -3957.000000)">
          <g id="Group" transform="translate(542.000000, 3950.000000)">
            <g id="Icon/Filled/Plus">
              <g id="Icon/Stroke/Plus">
                <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                <path
                  d="M16.4525203,11.2188148 L16.4530894,11.2188148 L12.7812195,11.2188148 L12.7812195,7.55382564 C12.7812195,7.25219717 12.5369919,7 12.2353659,7 L11.7655285,7 C11.4639837,7 11.2186992,7.25219717 11.2186992,7.55382564 L11.2186992,11.2188148 L7.54691057,11.2188148 C7.24544715,11.2188148 7,11.4627192 7,11.764429 L7,12.2369531 C7,12.538419 7.24536585,12.7812665 7.54691057,12.7812665 L11.2187805,12.7812665 L11.2187805,16.4595078 C11.2187805,16.7609737 11.4639837,17 11.7656098,17 L12.2354472,17 C12.5370732,17 12.7813008,16.7608924 12.7813008,16.4595078 L12.7813008,12.7812665 L16.4525203,12.7812665 C16.7541463,12.7812665 17,12.5383377 17,12.2369531 L17,11.764429 C17,11.4627192 16.7541463,11.2188148 16.4525203,11.2188148 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Plus;
