import styled from 'styled-components';

const StyledCheckbox = styled.label`
  input {
    position: relative;
  }
  span {
    padding-left: 18px;
    color: #2a2f34;
    font-family: 'RobotoRegular', sans-serif;
    font-size: 14px;
    line-height: 1.14;
    cursor: pointer;
  }
  .errorInput {
    color: red;
  }
`;
export default StyledCheckbox;
