import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'src/components/PrivateRoute';
import Login from 'src/pages/Login';
import ForgotPassword from 'src/pages/ForgotPassword';
import Routes from 'src/config/routes';
import MainRoutes from '../components/MainRoutes/MainRoutes';

const AppRoute = () => {
  return (
    <Switch>
      <Route path={Routes.Login} exact component={Login} />
      <Route path={Routes.ForgotPassword} exact component={ForgotPassword} />
      <PrivateRoute path="/" component={() => <MainRoutes />} />
      <Redirect to={Routes.Login} />
    </Switch>
  );
};

export default AppRoute;
