import styled from 'styled-components';

const StyledForgotPasswordForm = styled.div`
  .wrapper {
    max-width: 432px;
  }

  form {
    margin-top: 45px;
  }

  .inputLabel {
    color: ${props => props.theme.main.colors.colorInputLabel};
    font-family: 'RobotoMedium', sans-serif;
    margin-bottom: 10px;
    display: block;
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  .passwordMessage {
    padding-top: 10px;
    color: green;
  }

  form .formRow {
    margin-bottom: 20px;
  }

  .rememberLogin {
    display: flex;
    justify-content: center;
  }

  .formAction {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  input {
    height: 50px;
    width: 100%;
    padding: 20px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    .wrapper form .termsAndPass {
      display: block;
      text-align: center;
      line-height: 1.5;
    }
    .rememberLogin {
      padding-left: 10px;
    }
  }
`;
export default StyledForgotPasswordForm;
