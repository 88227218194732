import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router';
import { User as UserIcon, Eye, Calendar } from 'src/assets/icons';
import { Input, DateInput } from 'src/components';
import { isAdmin } from 'src/services/user';
import { modifyUser } from 'src/actions';
import { ModifyUserRequestType } from 'src/actions/modifyUser';
import Routes from 'src/config/routes';

type ComponentProps = {
  isEdit: boolean;
  initialValues?: ModifyUserRequestType;
  editAccount: boolean;
  createUser: boolean;
};

const UserForm = (props: ComponentProps) => {
  const { isEdit, initialValues, editAccount } = props;
  const [showPass, setShowPass] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const handleSendingForm = (flag: boolean) => {
    setLoading(flag);
    history.push(Routes.Dashboard);
  };

  const prepareData = (data: ModifyUserRequestType) => {
    const privileges: { [key: string]: number } = {
      admin: 0,
      master: 0,
      user: 0,
    };
    privileges[data.privileges] = 1;
    return Object.assign(data, { privileges: JSON.stringify(privileges) });
  };

  const handleOnSubmit = (values: ModifyUserRequestType) => {
    modifyUser(prepareData(values), handleSendingForm);
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const getDisabledClassName = () => {
    return `formRow ${!isAdmin() && editAccount ? 'disabled' : ''}`;
  };

  const initialState = isEdit || editAccount ? initialValues : undefined;

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialState}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="createUserSection">
          <div className="logoPlaceholder">
            <UserIcon />
            <span>Enter needed data about new user</span>
          </div>
          <div className="createUserForm">
            {!editAccount && (
              <div className="selectType">
                <div className="selectTypeRadio">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="inputLabel">
                    Admin
                    <Input type="radio" name="privileges" value="admin" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="selectTypeRadio">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="inputLabel">
                    User
                    <Input type="radio" name="privileges" value="user" />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            )}
            <div className={getDisabledClassName()}>
              <span className="inputLabel">Name</span>
              <Input
                type="text"
                placeholder="Write name here"
                name="name"
                disabled={!isAdmin()}
              />
            </div>
            <div className={getDisabledClassName()}>
              <span className="inputLabel">Username</span>
              <Input
                type="text"
                placeholder="Write username here"
                name="username"
                disabled={!isAdmin()}
              />
            </div>
            <div className={getDisabledClassName()}>
              <span className="inputLabel">E-mail</span>
              <Input
                type="email"
                placeholder="Write e-mail here"
                name="email"
                disabled={!isAdmin()}
              />
            </div>
            {isAdmin() ? (
              <div>
                <div className="formRow password">
                  <span className="inputLabel">Password</span>
                  <Input
                    type={showPass ? 'text' : 'password'}
                    placeholder="Write password here"
                    name="password"
                  />
                  <button type="button" onClick={() => handleShowPass()}>
                    <Eye />
                  </button>
                </div>
                <div className="formRow calendar">
                  <div className="calendarArea">
                    <span className="inputLabel">Start Date</span>
                    <DateInput placeholder="yyyy-mm-dd" name="valid_from" />
                    <button type="button">
                      <Calendar />
                    </button>
                  </div>
                  <div className="calendarArea">
                    <span className="inputLabel">End Date</span>
                    <DateInput placeholder="yyyy-mm-dd" name="valid_to" />
                    <button type="button">
                      <Calendar />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="formHeading">
                  <h3>Change password</h3>
                </div>
                <div className="formRow password">
                  <span className="inputLabel">Old Password</span>
                  <Input
                    type={showPass ? 'text' : 'password'}
                    placeholder="Write password here"
                    name="oldPassword"
                  />
                  <button type="button" onClick={() => handleShowPass()}>
                    <Eye />
                  </button>
                </div>
                <div className="formRow password">
                  <span className="inputLabel">New Password</span>
                  <Input
                    type={showPass ? 'text' : 'password'}
                    placeholder="Write password here"
                    name="newPassword"
                  />
                  <button type="button" onClick={() => handleShowPass()}>
                    <Eye />
                  </button>
                </div>
                <div className="formRow password">
                  <span className="inputLabel">Confirm New Password</span>
                  <Input
                    type={showPass ? 'text' : 'password'}
                    placeholder="Write password here"
                    name="confirmPassword"
                  />
                  <button type="button" onClick={() => handleShowPass()}>
                    <Eye />
                  </button>
                </div>
              </div>
            )}
            {!editAccount && !isEdit && !loading ? (
              <div className="createUserButton">
                <button type="submit">Create User</button>
              </div>
            ) : (
              <div className="editBtnArea">
                <div className="editUserButton">
                  <button type="submit">Save changes</button>
                </div>
              </div>
            )}
            {loading && (
              <Loader
                type="TailSpin"
                color="#4277F8"
                height={50}
                width={50}
                className="loader"
              />
            )}
          </div>
        </form>
      )}
    />
  );
};

export default UserForm;
