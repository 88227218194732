import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import { getSessionID } from 'src/services/user';
import Routes from 'src/config/routes';

interface Props extends RouteProps {
  component: any;
}

const ProtectedRoute = ({ component: Component, ...rest }: Props) => (
  <Route {...rest}>
    {props =>
      getSessionID() ? <Component {...props} /> : <Redirect to={Routes.Login} />
    }
  </Route>
);

export default ProtectedRoute;
