import { getSessionID } from 'src/services/user';
import errMessage from 'src/helpers/errorMessage';
import http from 'src/services/http';

export type ModifyUserRequestType = {
  name: string;
  email: string;
  valid_from: string;
  valid_to: string;
  username: string;
  password: string;
  privileges: string;
  terms_conditions?: number;
};

type ModifyUserResponseType = {
  message: string;
};

type CallBackType = (flag: boolean, message?: string) => void;

const modifyUser = async (
  request: ModifyUserRequestType,
  callBack: CallBackType
) => {
  try {
    await callBack(true);
    const sessionID = await getSessionID();
    const response: ModifyUserResponseType = await http(`/users/${sessionID}`, {
      method: 'POST',
      body: { ...request },
    });
    if (response && response.message) {
      await callBack(false, response.message);
    }
  } catch (e) {
    errMessage(e);
  }
};

export default modifyUser;
