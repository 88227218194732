import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import StyledCreateUser from 'src/pages/UserInfo/userInfoStyles';
import { HeaderWithSearch } from 'src/components';
import { getUserData, getSessionID, getUserName } from 'src/services/user';
import { ModifyUserRequestType } from 'src/actions/modifyUser';
import { Link } from 'react-router-dom';
import Routes from 'src/config/routes';
import { prepareInitialValues } from 'src/helpers';
import UserRoutes from './routes';
import UserForm from './UserForm';

type MatchParams = {
  id: string;
};

type ComponentProps = RouteComponentProps<MatchParams>;

const UserInfo = (props: ComponentProps) => {
  const { match, history } = props;
  const userName = match.params.id;
  const editAccount = match.path === Routes.EditAccount;
  const createUser = match.path === UserRoutes.userNew;
  const isEdit = !!userName;

  const [initialValues, setInitialValues] = useState<
    ModifyUserRequestType | undefined
  >(undefined);

  useEffect(() => {
    const sessionID = getSessionID();
    const userNameLocal = getUserName();
    if (!sessionID && !userName) {
      history.push(Routes.Login);
    }
    const runEffect = async () => {
      const response = await getUserData(userName || userNameLocal, sessionID);
      if (response) {
        const { user } = await response;
        await setInitialValues(prepareInitialValues(user));
      }
    };
    runEffect();
  }, [history, userName]);

  const title = isEdit ? 'Edit User' : 'Create User';
  const titleAccount = editAccount && 'Edit Account';

  return (
    <StyledCreateUser>
      <HeaderWithSearch />
      <div className="main">
        <div className="brCrumbsHead">
          <div className="breadCrumbs">
            <Link className="pageCrumbs" to="/dashboard">
              Dashboard
            </Link>
            <p className="pageCrumbs">{`/ ${titleAccount || title}`}</p>
          </div>
          <div className="pageHeading">
            <h2>{titleAccount || title}</h2>
          </div>
        </div>
        <UserForm
          isEdit={isEdit}
          initialValues={initialValues}
          editAccount={editAccount}
          createUser={createUser}
        />
      </div>
    </StyledCreateUser>
  );
};

export default UserInfo;
