import React, { ReactChild } from 'react';

type Props = { children: ReactChild };

const MainLayout = ({ children }: Props) => (
  <div>
    <main>{children}</main>
  </div>
);

export default MainLayout;
