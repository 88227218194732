import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Content from 'src/pages/AppRoute';
import { ThemeProvider } from 'styled-components';
import { theme } from 'src/common/theme';

export const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Content />
      </Router>
    </ThemeProvider>
  );
};

export default App;
