export const createTxt = (data: string) => {
  const element = document.createElement('a');
  const file = new Blob([data], {
    type: 'text/plain',
    endings: 'transparent',
  });
  element.href = URL.createObjectURL(file);
  element.download = 'CalculationResults.txt';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
