export const prepareInitialValues = (user: User) => {
  const { valid, privileges, ...rest } = user;
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const priv = Object.keys(privileges).filter(k => privileges[k] === 1)[0];
  return {
    // eslint-disable-next-line @typescript-eslint/camelcase
    valid_to: valid.to,
    // eslint-disable-next-line @typescript-eslint/camelcase
    valid_from: valid.from,
    privileges: priv,
    ...rest,
  };
};
