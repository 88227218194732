import React from 'react';
import { Field } from 'react-final-form';

type TypeTypes = 'text' | 'radio' | 'password' | 'email' | 'calendar' | 'file';

type InputTypes = {
  placeholder?: string;
  name: string;
  type: TypeTypes;
  value?: string;
  id?: string;
  disabled?: boolean;
};

const Input: React.FC<InputTypes> = (props: InputTypes) => {
  const { type, name, placeholder, value, disabled, id = '' } = props;
  const getNotRequiredItems = name === 'length' || name === 'price';
  const required = (data: string) =>
    data || getNotRequiredItems ? undefined : 'Required';

  return (
    <Field
      name={name}
      validate={required}
      value={type === 'radio' && value ? value : undefined}
      type={type}
    >
      {({ input, meta }) => (
        <>
          <input
            {...input}
            type={type}
            placeholder={placeholder}
            autoComplete="off"
            disabled={disabled}
            id={id}
          />
          {meta.error && meta.touched && (
            <span className="errorInput">{meta.error}</span>
          )}
        </>
      )}
    </Field>
  );
};
export default Input;
