import React from 'react';
import { useHistory } from 'react-router-dom';
import StyledHeading from './HeadingStyles';

type HeadingProps = {
  heading: string;
  text: string;
  isError?: boolean;
  isValidationErr?: boolean;
};

type LocationState = {
  expired: string;
};

const Heading = (props: HeadingProps) => {
  const { isError, heading, text, isValidationErr } = props;
  const { location } = useHistory<LocationState>();
  const isExpired = location.state && location.state.expired;
  return (
    <StyledHeading>
      <h1>{heading}</h1>
      {isExpired ? (
        <p className="expiredError">
          You were logged out due to inactivity! Please log in again!
        </p>
      ) : (
        <p>{text}</p>
      )}
      {isError && (
        <p className="errorMessage">
          Ooops...! Something went worng, please try again later.
        </p>
      )}
      {isValidationErr && (
        <p className="errorMessage">
          Wrong username or password. Please try again.
        </p>
      )}
    </StyledHeading>
  );
};
export default Heading;
