import React from 'react';

const Delete = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-322.000000, -3954.000000)">
          <g
            id="Icon/Filled/Delete"
            transform="translate(318.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M17.9285453,8.21875818 L17.9285453,18.7656373 C17.9285453,19.9284642 16.967518,20.875 15.7857024,20.875 L15.7857024,20.875 L7.21426434,20.875 C6.03244878,20.875 5.07142145,19.9284642 5.07142145,18.7656373 L5.07142145,18.7656373 L5.07142145,8.21875818 L17.9285453,8.21875818 Z M13.6428595,4 L13.6428595,5.05468136 L19,5.05468136 L19,7.16404409 L4,7.16404409 L4,5.05468136 L9.35714048,5.05468136 L9.35714048,4 L13.6428595,4 Z"
                id="Combined-Shape"
                fill="#B6B7C2"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Delete;
