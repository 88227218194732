import styled from 'styled-components';

const StyledHeading = styled.div`
  h1 {
    padding-bottom: 26px;
  }
  p {
    font-family: 'RobotoRegular', sans-serif;
    font-size: 16px;
  }
  .errorMessage,
  .expiredError {
    color: red !important;
    padding-top: 10px;
  }
`;
export default StyledHeading;
