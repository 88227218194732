import styled from 'styled-components';

const StyledCreateUser = styled.div`
   {
    background-color: #f5f6f8;
  }

  //breadCrumbs
  .main {
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 77px);
    display: flex;
    flex-direction: column;
  }

  .breadCrumbs {
    margin-top: 20px;
    display: flex;
  }

  .breadCrumbs a {
    padding-right: 5px;
  }

  .pageCrumbs {
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    font-size: 14px;
    line-height: 1.14;
    color: ${props => props.theme.main.colors.colorActivePage};
  }

  .pageHeading {
    margin-top: 35px;
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .createUserSection {
    background-color: ${props => props.theme.main.colors.colorWhite};
    border: 1px solid ${props => props.theme.main.colors.colorWhite};
    border-radius: 4px;
    display: flex;
    padding-top: 35px;
    margin-bottom: 35px;
  }

  .logoPlaceholder {
    max-width: 396px;
    flex: 1;
  }

  .logoPlaceholder svg {
    display: block;
    width: 146px;
    height: 146px;
    margin: 0 auto;
  }

  .logoPlaceholder span {
    display: block;
    text-align: center;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontOpenSansSemibold};
    color: ${props => props.theme.main.colors.colorInputLabel};
    line-height: 1.57;
    padding-top: 30px;
  }
  //radioBtn

  .selectType {
    display: flex;
  }

  .selectTypeRadio {
    margin-right: 50px;
  }

  .selectTypeRadio .inputLabel {
    color: #515151;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontOpenSansSemibold};
    line-height: 1.57;
  }

  .createUserSection .createUserForm .selectTypeRadio .errorInput {
    position: absolute;
    left: 0;
    bottom: -20px;
  }

  .createUserForm {
    max-width: 455px;
    flex: 1;
  }

  .password .inputLabel,
  .formRow .inputLabel {
    color: ${props => props.theme.main.colors.colorInputLabel};
    font-family: ${props => props.theme.main.fonts.fontFamily.fontRobotoMedium};
    line-height: 1.57;
  }

  .formRow {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .formRow:first-child {
    margin-top: 0;
  }

  .formRow input {
    height: 50px;
    width: 100%;
    padding-left: 20px;
    margin-top: 10px;
  }

  .formRow.calendar,
  .editBtnArea {
    display: flex;
    flex-direction: row;
  }

  .formRow.disabled {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .formRow.disabled .inputLabel {
    font-size: 20px;
    line-height: 1.35;
    color: ${props => props.theme.main.colors.colorBlackMain};
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontOpenSansSemibold};
  }

  .formRow.disabled input:disabled {
    color: ${props => props.theme.main.colors.colorGrey};
    font-size: 16px;
    line-height: 1.18;
    border: none;
    padding: 0;
    height: 35px;
    margin-top: 0;
  }

  .calendarArea {
    width: 100%;
    position: relative;
  }

  .calendarArea:first-child {
    padding-right: 6px;
  }

  .calendarArea:last-child {
    padding-left: 6px;
  }

  .calendarArea svg,
  .password svg {
    margin: 15px;
  }

  .password {
    position: relative;
  }

  .calendarArea button,
  .password button {
    content: '';
    position: absolute;
    top: 33px;
    right: 7px;
    background-color: transparent;
    padding: 0;
  }

  .calendarArea button {
    cursor: default;
  }

  .createUserForm button:hover path {
    fill: #4277f8;
  }

  .calendarArea:last-child button,
  .password button {
    right: 1px;
  }

  .createUserForm .calendarArea button:hover path {
    fill: rgb(182, 183, 194);
  }

  .calendarArea:before {
    content: '';
    display: block;
    position: absolute;
    width: 47px;
    top: 56px;
    right: 30px;
    border-bottom: 1px solid #e1e1e1;
    transform: rotate(90deg);
  }

  .calendarArea:last-child:before {
    right: 25px;
  }

  .createUserButton {
    margin: 25px 0;
  }

  .createUserButton button,
  .editUserButton button {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    width: 100%;
    padding: 18px 0;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .editUserButton button {
    width: 70%;
  }

  .createUserButton button:hover,
  .editUserButton button:hover {
    background-color: #2963f0;
  }

  .createUserButton button:active,
  .editUserButton button:active {
    background-color: #114cda;
  }

  .editBtnArea {
    justify-content: space-between;
    margin: 25px 0;
  }

  .editUserButton {
    width: 100%;
    max-width: 4555px;
  }

  .deleteButton {
    align-self: center;
  }

  .deleteButton button {
    color: ${props => props.theme.main.colors.colorBlueMain};
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    font-size: 14px;
    line-height: 1.14;
    padding: 0;
  }

  .deleteButton button svg {
    margin-right: 10px;
    vertical-align: bottom;
  }

  .deleteButton button:active,
  .deleteButton button:hover {
    background-color: transparent;
  }

  .formHeading {
    margin: 35px 0;
    color: ${props => props.theme.main.colors.colorBlackMainl};
  }

  @media screen and (max-width: 1340px) {
    .main {
      padding: 0 70px;
    }
  }

  @media screen and (max-width: 1100px) {
    .logoPlaceholder {
      display: none;
    }

    .createUserForm {
      margin: 0 auto;
      padding: 0 70px;
      max-width: 595px;
    }
  }
`;
export default StyledCreateUser;
