import styled from 'styled-components';

const StyledCalculator = styled.div`
   {
    background-color: #f5f6f8;
  }

  .main {
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 76px);
  }

  .sectionHeading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontOpenSansSemibold};
    line-height: 1.37;
  }

  .pageHeading {
    margin-top: 35px;
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .uploadedFileMessage,
  .copiedMessage {
    display: block;
    color: ${props => props.theme.main.colors.colorBlueMain};
    font-size: 17px;
    margin-top: 5px;
    align-self: flex-end;
  }

  .copiedMessage {
    margin-top: 0;
    opacity: 0;
    animation: disappear 4s ease;
    cursor: default;
    padding: 0 15px 30px 0;
  }

  .uploadedFileErrMessage {
    color: red;
    display: block;
    margin-top: 5px;
    font-size: 16px;
  }

  .spanResult {
    display: flex;
  }

  .fileSelection {
    width: 100%;
    max-width: 173px;
    margin: 0 0 28px;
  }

  .fileSelection button {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    width: 100%;
    padding: 15px 0;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .fileSelection button:hover {
    background-color: #2963f0;
  }

  .fileSelection button:active {
    background-color: #114cda;
  }

  .fileSelection button svg {
    margin: 0 15px 0 0;
    vertical-align: sub;
  }

  .calcAndResults {
    display: flex;
    justify-content: space-between;
  }

  .calcAndResults .col {
    width: 100%;
    max-width: 550px;
  }

  .collapsedCalcSection .row {
    display: flex;
    justify-content: space-between;
  }

  .collapsedCalcSection .rowActions {
    padding-top: 4px;
  }

  .rowActions {
    position: relative;
  }

  .rowActionsButton {
    padding: 0;
    background-color: transparent;
  }

  .rowActionsButton:first-child {
    margin-right: 25px;
  }

  .collapsedInfo ul {
    display: flex;
    margin-left: 17px;
    flex-wrap: wrap;
  }

  .collapsedInfo li {
    list-style-type: disc;
    color: ${props => props.theme.main.colors.colorBlueMain};
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .collapsedInfo li span {
    color: ${props => props.theme.main.colors.colorInputLabel};
    margin-left: -6px;
    white-space: nowrap;
  }

  .collapsedInfo.detailed span {
    line-height: 1.37;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .collapsedInfo.detailed .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collapsedInfo.detailed li {
    padding-bottom: 10px;
  }

  .tooltip {
    display: none;
  }

  .rowActionsButton:hover .tooltip {
    position: absolute;
    display: inherit;
    bottom: 65px;
    right: 17px;
    font-size: 14px;
    line-height: 1.14;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    color: ${props => props.theme.main.colors.colorWhite};
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    border-radius: 4px;
    padding: 2px 15px;
  }

  .rowActionsButton:last-child:hover .tooltip {
    bottom: 65px;
    right: -31px;
  }

  .rowHeading {
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontOpenSansSemibold};
    line-height: 1.35;
    margin-bottom: 35px;
  }

  .colSection {
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    background-color: ${props => props.theme.main.colors.colorWhite};
    padding: 35px 50px 0 50px;
    margin-bottom: 40px;
    height: fit-content;
  }

  .sectionImportFile .submitButton {
    margin-bottom: 30px;
  }

  .customSelect {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .customSelect label {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    font-family: ${props => props.theme.main.fonts.fontFamily.fontRobotoMedium};
    line-height: 1.57;
    color: ${props => props.theme.main.colors.colorInputLabel};
  }

  //.customSelect label svg {
  //  content: '';
  //  position: absolute;
  //  bottom: 20px;
  //  right: 15px;
  //  cursor: pointer;
  //  transform: rotate(180deg);
  //}

  .calcTypeSelect {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    position: relative;
    z-index: 15;
    background: transparent;
  }

  .calcTypeSelect:hover {
    border-color: ${props => props.theme.main.colors.colorBlueMain};
  }

  .calcTypeSelect {
    appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: unset;
    -webkit-appearance: none;
    color: ${props => props.theme.main.colors.colorGrey};
    padding-left: 20px;
  }

  .selectOptions {
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(50, 54, 64, 0.08);
    z-index: 15;
  }

  .selectOptions button {
    background: none;
    color: ${props => props.theme.main.colors.colorBlackMain};
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    display: flex;
    padding: 20px 0 10px 20px;
    width: 100%;
  }

  .selectOptions button:last-child {
    padding-bottom: 20px;
  }

  .selectOptions button:hover {
    color: ${props => props.theme.main.colors.colorBlueMain};
  }

  .selectOptions {
    position: absolute;
    top: 91px;
    left: 0;
  }

  //.resultsOfCalc

  .resultsRow {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
  }

  .resultsRow p {
    line-height: 1.37;
    flex: 1;
  }

  .resultsRow span {
    font-family: ${props => props.theme.main.fonts.fontFamily.fontRobotoMedium};
    line-height: 1.18;
    letter-spacing: 0.2px;
    color: ${props => props.theme.main.colors.colorInputLabel};
  }

  .resultRowAction {
    display: flex;
    justify-content: flex-end;
  }

  .copyResults {
    color: ${props => props.theme.main.colors.colorBlueMain};
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    font-size: 14px;
    line-height: 1.14;
    text-decoration: underline;
    padding: 15px 0 30px 0;
  }

  .rowActionsButton,
  .copyResults:hover {
    //.addCalcButton button:hover
    background: none;
  }

  .copyResults svg {
    vertical-align: sub;
    margin-right: 10px;
  }

  .rowActionsButton:hover path,
  .copyResults:hover path {
    fill: ${props => props.theme.main.colors.colorBlueMain};
  }

  .deleteButton path {
    fill: ${props => props.theme.main.colors.colorBlueMain};
  }

  // .addCalcButton {
  //   display: inline-block;
  //   padding: 15px 0;
  //   max-width: 250px;
  //   width: 100%;
  // }
  //
  // .addCalcButton button {
  //  color: {props => props.theme.main.colors.colorBlueMain};
  //   padding: 0;
  //   text-transform: uppercase;
  // }
  //
  // .addCalcButton svg path {
  //  fill: .colorBlueMain};
  // }
  //
  // .addCalcButton svg {
  //   margin-right: 10px;
  // }
  //different margin and label color between this and input in createUserPage
  .formRow {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .formRow label {
    font-family: ${props => props.theme.main.fonts.fontFamily.fontRobotoMedium};
    line-height: 1.57;
    color: ${props => props.theme.main.colors.colorInputLabel};
  }

  .formRow input {
    height: 50px;
    width: 100%;
    padding-left: 20px;
    margin-top: 10px;
  }

  //radioButtons
  .rowRadio p {
    font-family: ${props => props.theme.main.fonts.fontFamily.fontRobotoMedium};
    line-height: 1.57;
    color: ${props => props.theme.main.colors.colorInputLabel};
    margin-bottom: 10px;
    font-size: 14px;
  }

  .selectTypeRadio {
    margin-bottom: 30px;
  }

  .selectType .errorInput {
    position: absolute;
    left: 0;
    bottom: -18px;
  }

  .rowActionButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .submitButton button {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    width: 100%;
    padding: 18px 0;
    border-radius: 4px;
    text-transform: uppercase;
  }
  //forDisabledBtn
  .submitButton button:disabled,
  .submitButton button[disabled] {
    background-color: rgba(66, 119, 248, 0.5);
  }

  .submitButton button:hover {
    background-color: #2963f0;
  }

  .submitButton button:active {
    background-color: #114cda;
  }

  .submitButton {
    width: 70%;
    display: flex;
    max-width: 314px;
  }

  .colAction {
    align-self: flex-start;
    padding: 15px 0;
    margin-bottom: 40px;
    display: flex;
  }

  .modalWindow {
    right: calc(50% - 200px);
    position: fixed;
    top: 35%;
    z-index: 100;
    width: 400px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .cancelButton {
    max-width: 90px;
    margin-left: 20px;
  }

  .deleteButton {
    color: ${props => props.theme.main.colors.colorBlueMain};
    text-transform: uppercase;
    padding: 0 10px 0 0;
    white-space: nowrap;
    font-size: 16px;
  }

  .deleteButton svg {
    margin-right: 10px;
    vertical-align: top;
  }

  .deleteButton:active,
  .deleteButton:hover {
    background-color: transparent;
  }

  @keyframes disappear {
    0% {
      opacity: 0;
    }

    4% {
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 1340px) {
    .main {
      padding: 0 70px;
      max-width: 1340px;
    }
  }

  @media screen and (max-width: 1250px) {
    .calcAndResults {
      flex-direction: column;
      align-items: center;
    }

    .calcAndResults .col {
      display: flex;
      flex-direction: column;
      max-width: 600px;
    }

    .sectionResults,
    .sectionImportFile {
      margin-top: 40px;
    }
  }
`;
export default StyledCalculator;
