import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  DashboardUserList,
  DashboardPagination,
  HeaderWithSearch,
} from 'src/components';
import { getUsersList, getSessionID } from 'src/services/user';
import { Plus } from 'src/assets/icons';
import StyledDashboard from 'src/pages/Dashboard/dashboardStyles';
import UserRoutes from 'src/pages/UserInfo/routes';
import useSortableData from 'src/components/DashboardUserList/useSortableData';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const results: User[] = userList.filter(
      (person: User) =>
        person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, userList]);

  useEffect(() => {
    const runEffect = async () => {
      const sessionID = getSessionID();
      setIsLoading(true);
      const response = await getUsersList(sessionID);
      if (response) {
        const { user } = await response;
        setIsLoading(false);
        await setUserList(user);
      }
    };
    runEffect();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setSearchTerm(e.currentTarget.value);
  };
  const { items, requestSort, sortConfig } = useSortableData(searchResults);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <StyledDashboard>
      <HeaderWithSearch handleOnSearch={handleChange} searchTerm={searchTerm} />
      <div className="mainDashboard">
        <div className="breadCrumbs">
          <p className="dashboardCrumbs">Dashboard</p>
        </div>
        <div className="dashboardHeading">
          <h2>Users</h2>
          <Link className="btnCreateUser" to={UserRoutes.userNew}>
            <span className="iconPlus">
              <Plus />
            </span>
            New user
          </Link>
        </div>
        <DashboardUserList
          userList={currentItems}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          requestSort={requestSort}
          sortConfig={sortConfig}
        />
        <DashboardPagination
          totalList={searchResults.length}
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
