import React, { useState, ChangeEvent } from 'react';
import { Form } from 'react-final-form';
import Loader from 'react-loader-spinner';
import { SelectComponent } from 'src/components';
import { calculationFileTypeConfig } from 'src/config/calculationConfig';
import calculateWithFile from 'src/actions/calculateWithFile';
import { Import } from 'src/assets/icons';

interface ValuesTypes {
  calculation_file_type: {
    value: string;
    label: string;
  };
}

type CalculationFormType = {
  createTxtFromUploadFile: (result?: number[]) => void;
  uploadedFile: any;
  setUploadedFile: (e: any) => void;
};

const ImportCalculationForm = (props: CalculationFormType) => {
  const { createTxtFromUploadFile, uploadedFile, setUploadedFile } = props;
  const [isFileRequired, setFileRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  let fileInput: HTMLElement | null = null;
  const upload = () => {
    if (fileInput) {
      fileInput.click();
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFileRequired(false);
      setUploadedFile(event.target.files[0]);
    }
  };

  const resultCallback = (flag: boolean, data?: number[]) => {
    setLoading(flag);
    if (data) {
      createTxtFromUploadFile(data);
    }
  };

  const handleOnSubmit = (values: ValuesTypes) => {
    if (values && uploadedFile !== undefined) {
      calculateWithFile(
        // eslint-disable-next-line @typescript-eslint/camelcase
        { file_type: values.calculation_file_type.value, file: uploadedFile },
        resultCallback
      );
    } else {
      setFileRequired(true);
    }
  };
  return (
    <Form
      onSubmit={handleOnSubmit}
      validateOnBlur
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={(...args) => {
            handleSubmit(...args);
            form.reset();
          }}
          className="calcSection"
        >
          <SelectComponent
            name="calculation_file_type"
            options={calculationFileTypeConfig}
            label="File type"
          />
          <div>
            <div className="fileSelection">
              <button type="button" onClick={upload}>
                <Import />
                Import file
              </button>
              {uploadedFile && (
                <span className="uploadedFileMessage">File imported!</span>
              )}
              {isFileRequired && (
                <span className="uploadedFileErrMessage">
                  Please import xlsx file!
                </span>
              )}
            </div>
            {loading ? (
              <Loader
                type="TailSpin"
                color="#4277F8"
                height={50}
                width={50}
                className="loader"
              />
            ) : (
              <div className="submitButton">
                <button type="submit">Calculate & Download</button>
              </div>
            )}
          </div>
          <input
            hidden
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              onFileChange(event)
            }
            type="file"
            ref={input => {
              fileInput = input;
            }}
          />
        </form>
      )}
    />
  );
};

export default ImportCalculationForm;
