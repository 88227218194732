import React from 'react';

const Import = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-154.000000, -4131.000000)">
          <g
            id="Icon/Stroke/Import"
            transform="translate(150.000000, 4129.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <g
                id="download"
                transform="translate(4.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path
                  d="M10.5952586,0.151679544 C10.505269,0.0568985494 10.381889,0 10.254017,0 L2.59801772,0 C1.18528662,0 0.0191658272,1.16147906 0.0191658272,2.57406044 L0.0191658272,17.0515969 C0.0191658272,18.4643279 1.18528662,19.625807 2.59801772,19.625807 L12.9891901,19.625807 C14.4019212,19.625807 15.568042,18.4643279 15.568042,17.0515969 L15.568042,5.55584387 C15.568042,5.43261358 15.5111435,5.31417479 15.4305871,5.22403549 L10.5952586,0.151679544 Z M10.7327135,1.67341628 L13.9705404,5.07235594 L11.8657433,5.07235594 C11.240009,5.07235594 10.7327135,4.56985191 10.7327135,3.94411759 L10.7327135,1.67341628 Z M12.9891901,18.6776975 L2.59801772,18.6776975 C1.71159821,18.6776975 0.967275339,17.9429575 0.967275339,17.0515969 L0.967275339,2.57406044 C0.967275339,1.68764093 1.70680676,0.948109512 2.59801772,0.948109512 L9.78460397,0.948109512 L9.78460397,3.94411759 C9.78460397,5.09601374 10.7138471,6.02046546 11.8657433,6.02046546 L14.6199325,6.02046546 L14.6199325,17.0515969 C14.6199325,17.9429575 13.8804011,18.6776975 12.9891901,18.6776975 Z"
                  id="Shape"
                />
                <path
                  d="M11.6950476,15.4067796 L3.89216022,15.4067796 C3.63147504,15.4067796 3.41810549,15.6199994 3.41810549,15.8808343 C3.41810549,16.1415195 3.63147504,16.3548891 3.89216022,16.3548891 L11.6998391,16.3548891 C11.9605243,16.3548891 12.1738938,16.1415195 12.1738938,15.8808343 C12.1738938,15.6199994 11.9605243,15.4067796 11.6950476,15.4067796 Z"
                  id="Path"
                />
                <path
                  d="M7.44757092,13.704914 C7.53771019,13.799695 7.66094047,13.8565936 7.79360392,13.8565936 C7.92641712,13.8565936 8.04964741,13.799695 8.13963695,13.704914 L10.9176337,10.7231306 C11.0977626,10.5334188 11.083538,10.2300597 10.8939759,10.0547224 C10.7042642,9.87459351 10.4009051,9.8886684 10.2255677,10.0783801 L8.26765869,12.1783858 L8.26765869,7.00181568 C8.26765869,6.74098077 8.05428914,6.52776094 7.79360392,6.52776094 C7.53291873,6.52776094 7.31954918,6.74098077 7.31954918,7.00181568 L7.31954918,12.1783858 L5.3664316,10.0783801 C5.18630277,9.88881816 4.88758537,9.87459351 4.69802338,10.0547224 C4.50846139,10.2348512 4.49423675,10.5335685 4.67436555,10.7231306 L7.44757092,13.704914 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Import;
