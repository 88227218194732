export const calculationTypeConfig = [
  { value: 'pipe', label: 'Pipe' },
  { value: 'elbow', label: 'Elbow' },
  { value: 'reducer', label: 'Reducer' },
  { value: 't-piece', label: 'T-piece' },
  { value: 'branch', label: 'Branch' },
  { value: 'flange', label: 'Flange' },
  { value: 'collar', label: 'Collar' },
];

export const calculationFileTypeConfig = [
  { value: '1', label: 'No extra line + Summary sheet' },
  { value: '2', label: 'DESCAL' },
  { value: '3', label: 'VALMET' },
  { value: '4', label: 'PÖYRY 1' },
  { value: '5', label: 'OUTOKUMPU' },
  { value: '6', label: 'STORAENSO' },
  { value: '7', label: 'PÖYRY 2' },
  { value: '8', label: 'KEMIRA' },
];

export interface SizeInput {
  type: string;
  name: string;
  label: string;
}

export interface RadioValuesConfig {
  value: string;
  title: string;
  label: string;
}

export interface RadioInput {
  type: string;
  name: string;
  label: string;
  values: RadioValuesConfig[];
}

const SizeMMInputConfig: SizeInput = {
  type: 'text',
  name: 'size_mm',
  label: 'Size, mm',
};

const SizeDNInputConfig: SizeInput = {
  type: 'text',
  name: 'size_dn',
  label: 'Size DN, mm',
};

const SizeSInputConfig: SizeInput = {
  type: 'text',
  name: 'size_s',
  label: 'Size Small, mm',
};

const SizeBInputConfig: SizeInput = {
  type: 'text',
  name: 'size_b',
  label: 'Size Big, mm',
};

const ThicknessInputConfig: SizeInput = {
  type: 'text',
  name: 'thickness',
  label: 'Thickness',
};

const ThicknessSInputConfig: SizeInput = {
  type: 'text',
  name: 'thickness_s',
  label: 'Thickness Small',
};

const ThicknessBInputConfig: SizeInput = {
  type: 'text',
  name: 'thickness_b',
  label: 'Thickness Big',
};

const DN1InputConfig: SizeInput = {
  type: 'text',
  name: 'dn1',
  label: 'DN 1',
};

const DN2InputConfig: SizeInput = {
  type: 'text',
  name: 'dn2',
  label: 'DN 2',
};

const PriceInputConfig: SizeInput = {
  type: 'text',
  name: 'price',
  label: 'Price',
};

const LengthInputConfig: SizeInput = {
  type: 'text',
  name: 'length',
  label: 'Total length',
};

const ColorInputConfig: RadioInput = {
  type: 'radio',
  name: 'black',
  label: 'Color',
  values: [
    { value: '1', title: 'black', label: 'Black' },
    { value: '0', title: 'clear', label: 'Clear' },
  ],
};

const ReinforceInputConfig: RadioInput = {
  type: 'radio',
  name: 'reinforce',
  label: 'Reinforced',
  values: [
    { value: '1', title: 'yes', label: 'Yes' },
    { value: '0', title: 'no', label: 'No' },
  ],
};

interface InputsConfig {
  [key: string]: (SizeInput | RadioInput)[];
}

export const inputsConfig: InputsConfig = {
  pipe: [
    SizeMMInputConfig,
    ThicknessInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  elbow: [
    SizeMMInputConfig,
    ThicknessInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  reducer: [
    SizeSInputConfig,
    SizeBInputConfig,
    ThicknessSInputConfig,
    ThicknessBInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  't-piece': [
    SizeBInputConfig,
    ThicknessInputConfig,
    SizeSInputConfig,
    ThicknessSInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  branch: [
    ThicknessInputConfig,
    DN1InputConfig,
    DN2InputConfig,
    ReinforceInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  flange: [
    SizeDNInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
  collar: [
    SizeDNInputConfig,
    ThicknessInputConfig,
    ColorInputConfig,
    LengthInputConfig,
    PriceInputConfig,
  ],
};
