import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-209.000000, -3953.000000)">
          <g
            id="Icon/Filled/Calendar"
            transform="translate(206.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M18.75,5.25 L18.0000458,5.25 L18.0000458,3.74995421 C18.0000458,3.33604432 17.6640015,3 17.2499542,3 L16.5,3 C16.0859528,3 15.7500458,3.33604432 15.7500458,3.74995421 L15.7500458,5.25 L8.24995421,5.25 L8.24995421,3.74995421 C8.24995421,3.33604432 7.91404725,3 7.5,3 L6.75004579,3 C6.33599854,3 5.99995421,3.33604432 5.99995421,3.74995421 L5.99995421,5.25 L5.25,5.25 C4.00950623,5.25 3,6.25950623 3,7.5 L3,18.75 C3,19.9904938 4.00950623,21 5.25,21 L18.75,21 C19.9904938,21 21,19.9904938 21,18.75 L21,7.5 C21,6.25950623 19.9904938,5.25 18.75,5.25 Z M19.4999542,18.75 C19.4999542,19.1632233 19.1632233,19.4999542 18.75,19.4999542 L5.25,19.4999542 C4.83677674,19.4999542 4.50004579,19.1632233 4.50004579,18.75 L4.50004579,10.5300293 L19.4999542,10.5300293 L19.4999542,18.75 Z"
                id="Shape"
                fill="#B6B7C2"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Calendar;
