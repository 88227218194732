import React from 'react';

const User = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <circle id="path-1" cx="12" cy="12" r="12" />
      </defs>
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-654.000000, -3950.000000)">
          <g
            id="Icon/Filled/User"
            transform="translate(654.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <g id="Combined-Shape">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use id="Mask" fill="#F5F6F8" xlinkHref="#path-1" />
                <path
                  d="M5.38209522,18.4271852 C9.36397727,15.7439455 14.8004063,15.7439455 18.7822883,18.4271852 C19.3730403,18.8249513 19.7260274,19.4970878 19.7260274,20.2255989 L19.7260274,20.2255989 L19.7260274,24 L4.43835616,24 L4.43835616,20.2255989 C4.43835616,19.4970878 4.79130942,18.8249513 5.38209522,18.4271852 Z M15.1708123,7.85125097 C16.876578,9.55242851 16.876578,12.3105979 15.1708123,14.0117754 C13.4650467,15.7129529 10.6994385,15.7129529 8.99367288,14.0117754 C7.28790724,12.3105979 7.28790724,9.55242851 8.99367288,7.85125097 C10.6994385,6.15003963 13.4650467,6.15003963 15.1708123,7.85125097 Z"
                  fill="#B6B7C2"
                  fillRule="nonzero"
                  mask="url(#mask-2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default User;
