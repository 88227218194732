import React from 'react';
import LoginLayout from 'src/components/LoginLayout';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  return (
    <LoginLayout>
      <ForgotPasswordForm />
    </LoginLayout>
  );
};

export default ForgotPassword;
