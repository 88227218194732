import React from 'react';

const Copy = () => {
  return (
    <svg
      width="16px"
      height="18px"
      viewBox="0 0 16 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-266.000000, -3953.000000)">
          <g
            id="Icon/Filled/Copy"
            transform="translate(262.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <g
                id="copy-(1)"
                transform="translate(4.000000, 3.000000)"
                fill="#B6B7C2"
                fillRule="nonzero"
              >
                <path
                  d="M6.35465426,14.1617021 C4.65305852,14.1617021 3.26808511,12.7767287 3.26808511,11.075133 L3.26808511,3.63125001 L1.99720746,3.63125001 C0.89547874,3.63125001 0,4.52659574 0,5.62832446 L0,15.4325798 C0,16.5343085 0.89547874,17.4297872 1.99720746,17.4297872 L11.075133,17.4297872 C12.1768617,17.4297872 13.0723404,16.5343085 13.0723404,15.4325798 L13.0723404,14.1617021 L6.35465426,14.1617021 Z"
                  id="Path"
                />
                <path
                  d="M15.9772606,1.99720746 C15.9772606,0.894015966 15.0832447,0 13.9801862,0 L6.35465426,0 C5.25146277,0 4.35744681,0.894015966 4.35744681,1.99720746 L4.35744681,11.075133 C4.35744681,12.1783245 5.25146277,13.0723404 6.35465426,13.0723404 L13.9801862,13.0723404 C15.0832447,13.0723404 15.9772606,12.1783245 15.9772606,11.075133 L15.9772606,1.99720746 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Copy;
