import styled from 'styled-components';

const StyledDashboard = styled.div`
   {
    background-color: #f5f6f8;
  }

  .mainDashboard {
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 77px);
    display: flex;
    flex-direction: column;
  }

  .breadCrumbs {
    margin-top: 20px;
  }

  .dashboardCrumbs {
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    font-size: 14px;
    line-height: 1.14;
    color: ${props => props.theme.main.colors.colorActivePage};
  }

  .dashboardHeading {
    margin-top: 35px;
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btnCreateUser {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    color: ${props => props.theme.main.colors.colorWhite};
    padding: 15px 22px 15px 20px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .btnCreateUser:hover {
    background-color: #2963f0;
  }

  .btnCreateUser:active {
    background-color: #114cda;
  }

  .iconPlus {
    margin-right: 15px;
  }

  @media screen and (max-width: 1340px) {
    .mainDashboard {
      padding: 0 70px;
    }
  }

  @media screen and (max-width: 992px) {
    .dashboardTableSection {
      overflow: scroll;
    }

    .dashboardTableContainer {
      width: 830px;
    }
  }
`;
export default StyledDashboard;
