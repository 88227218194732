import { useState, useMemo } from 'react';

export type SortConfigTypes = {
  key: string;
  key2: string;
  direction: string;
};

const useSortableData = (items: User[]) => {
  const [sortConfig, setSortConfig] = useState<SortConfigTypes | null>(null);
  const sortedItems: User[] = useMemo(() => {
    const sortableItems: User[] = [...items];
    if (sortConfig && sortConfig.key2) {
      sortableItems.sort((a: any, b: any) => {
        if (
          a[sortConfig.key][sortConfig.key2] <
          b[sortConfig.key][sortConfig.key2]
        ) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (
          a[sortConfig.key][sortConfig.key2] >
          b[sortConfig.key][sortConfig.key2]
        ) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    if (sortConfig) {
      sortableItems.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort: any = (key: string, key2: string) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction, key2 });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
