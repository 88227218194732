import React from 'react';
import { Up } from 'src/assets/icons';
import StyledDashboardPagination from './DashboardPaginationStyles';

interface PaginationPropsTypes {
  totalList: number;
  itemsPerPage: number;
  paginate: (number: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
}

const DashboardPagination = (props: PaginationPropsTypes) => {
  const {
    totalList,
    itemsPerPage,
    paginate,
    currentPage,
    setCurrentPage,
  } = props;
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalList / itemsPerPage); i += 1) {
    pageNumbers.push(i);
  }
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <StyledDashboardPagination>
      <div className="paginationSection">
        <nav>
          <button type="button" className="prevPage" onClick={() => prevPage()}>
            <Up />
          </button>
          <ul>
            {pageNumbers.map((number: number) => (
              <li
                className={`pageItem ${currentPage === number && 'active'}`}
                key={number}
              >
                <button type="button" onClick={() => paginate(number)}>
                  {number}
                </button>
              </li>
            ))}
            {/* <li className="pageItem">
              <button type="button">...</button>
            </li> */}
          </ul>
          <button type="button" className="nextPage" onClick={() => nextPage()}>
            <Up />
          </button>
        </nav>
      </div>
    </StyledDashboardPagination>
  );
};

export default DashboardPagination;
