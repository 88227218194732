import React, { useState, CSSProperties, SyntheticEvent } from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
// eslint-disable-next-line import/extensions,import/no-unresolved
import { ValueType } from 'react-select/lib/types';
import { CalculationTypeOption } from 'src/models/Calculation';

type SelectTypes = {
  name: string;
  placeholder?: string;
  options: CalculationTypeOption[];
  defaultValue?: CalculationTypeOption;
  label: string;
  parentOnChange?: (value: string) => void;
  formType?: boolean;
};

const SelectComponent = (props: SelectTypes) => {
  const {
    name,
    placeholder,
    options,
    defaultValue,
    label,
    parentOnChange,
    formType,
  } = props;
  const [value, setValue] = useState<CalculationTypeOption | undefined>(
    undefined
  );

  const handleOnChange = (
    data: ValueType<CalculationTypeOption>,
    inputOnchange: (value: SyntheticEvent<HTMLInputElement>) => void
  ) => {
    if (parentOnChange) parentOnChange(data.value);
    inputOnchange(data);
    setValue(data);
  };

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: CSSProperties, state: any) => ({
      ...provided,
      height: 50,
      width: '100%',
      paddingLeft: '10px',
      marginTop: 10,
    }),
  };
  const required = (data: CalculationTypeOption) =>
    data ? undefined : 'Required';
  return (
    <Field name={name} type="select" value={value} validate={required}>
      {({ input, meta }) => (
        <div className="customSelect">
          <label htmlFor="typeSelect">
            {label}
            <Select
              {...input}
              id="typeSelect"
              options={options}
              defaultValue={defaultValue}
              placeholder={placeholder}
              styles={customStyles}
              isDisabled={formType}
              isSearchable={false}
              name={name}
              value={input.value}
              components={{ IndicatorSeparator: null }}
              onChange={data => handleOnChange(data, input.onChange)}
            />
            {meta.error && meta.touched && (
              <span className="errorInput">{meta.error}</span>
            )}
          </label>
        </div>
      )}
    </Field>
  );
};

export default SelectComponent;
