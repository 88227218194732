import React, { useState } from 'react';
import StyledCalculator from 'src/pages/Calculator/calculatorStyles';
import { Copy, Delete } from 'src/assets/icons';
import { HeaderWithSearch } from 'src/components';
import { CalculateType, CalculateResponseType } from 'src/actions/calculate';
import { createTxt, arrayUnique, arrayMove } from 'src/helpers';
import ModalWindow from 'src/components/DashboardUserList/ModalWindow';
import {
  inputsConfig,
  calculationTypeConfig,
} from 'src/config/calculationConfig';
import CollapsedCalcSection from './components/collapsedCalcSection';
import CalculationForm from './CalulationForm';
import EditForm from './EditForm';
import ImportCalculationForm from './ImportCalculationForm';

const Calculator = () => {
  const [results, setResult] = useState<
    (CalculateType & CalculateResponseType)[]
  >([]);
  const [uploadedFile, setUploadedFile] = useState<any>(undefined);
  const [isPopup, setShowingPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState<CalculateType[]>([]);
  const [formType, setFormType] = useState(true);
  const [indexCaclculation, setIndexCalculation] = useState<number | undefined>(
    undefined
  );
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string | undefined>(undefined);

  const downloadTxtFile = () => {
    const mergedData = results.map((item, i) => {
      const { message, black, ...restItem } = item;
      return {
        ...restItem,
        color: black === 1 ? 'black' : 'clear',
      };
    });
    let firstLine: string[] = [];
    mergedData.forEach(el => {
      firstLine = arrayUnique([...firstLine, ...Object.keys(el)]);
    });

    firstLine = arrayMove(firstLine, 'calculation_type', 0);
    firstLine = arrayMove(firstLine, 'total_price', firstLine.length - 1);
    firstLine = arrayMove(firstLine, 'total_ajh', firstLine.length - 2);
    firstLine = arrayMove(firstLine, 'total_length', firstLine.length - 3);

    let result = `${firstLine.join('; ')}\r\n`;
    mergedData.forEach((element, i) => {
      let row = '';
      firstLine.forEach((rowItem: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (element[rowItem]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          row += `${element[rowItem]}; `;
        } else {
          row += '- ; ';
        }
      });
      result += `${row}\r\n`;
    });
    createTxt(result);
  };

  const createTxtFromUploadFile = (data?: number[]) => {
    if (data) {
      const clonedData = data.slice().join('\r\n');
      createTxt(clonedData);
    }
  };

  const handleResults = (
    result: CalculateType & CalculateResponseType,
    form?: CalculateType
  ) => {
    setResult(oldResult => {
      const newResult = oldResult.slice();
      newResult.push(result);
      return newResult;
    });
    if (form) setFormData(oldForm => [...oldForm, form]);
  };

  const handleEditResults = (
    result: CalculateType & CalculateResponseType,
    form?: CalculateType
  ) => {
    if (indexCaclculation !== undefined) {
      setResult(oldResult => {
        const newResult = [...oldResult];
        newResult.splice(indexCaclculation, 1, result);
        return newResult;
      });
      if (form)
        setFormData(oldForm => {
          const copyOldForm = [...oldForm];
          copyOldForm.splice(indexCaclculation, 1, form);
          setIndexCalculation(undefined);
          return copyOldForm;
        });
    }
  };

  const clearPage = () => {
    setResult([]);
    setFormData([]);
    setUploadedFile(undefined);
    setShowingPopup(false);
  };

  const handleDeleteCalculation = () => {
    if (indexCaclculation !== undefined) {
      setResult(oldResult => {
        const newResult = [...oldResult];
        newResult.splice(indexCaclculation, 1);
        return newResult;
      });
      setFormData(oldForm => {
        const copyOldForm = [...oldForm];
        copyOldForm.splice(indexCaclculation, 1);
        setIndexCalculation(undefined);
        return copyOldForm;
      });
    }
    setDeleteModal(false);
  };

  const editCalculation = (index: number) => {
    setIndexCalculation(index);
    setActionType('edit');
  };

  const deleteCalculation = (index: number) => {
    setIndexCalculation(index);
    setActionType('delete');
    setDeleteModal(true);
  };

  const renderResult = () => {
    return (
      results &&
      results.map((item: CalculateResponseType, i: number) => {
        const currentType =
          results[i] && inputsConfig[results[i].calculation_type];
        const typeLabel =
          results[i] &&
          calculationTypeConfig.find(
            type => type.value === results[i].calculation_type
          );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="collapsedInfo detailed" key={i}>
            <div className="heading">
              <p>{`Calculation №:${i + 1}`}</p>
              <span>{`${results[i].ajh} AJH`}</span>
            </div>
            <ul>
              <li>
                <span>{`Type: ${typeLabel && typeLabel.label}`}</span>
              </li>
              {results[i] &&
                Object.keys(results[i]).map((calc: string) => {
                  if (
                    calc === 'length' ||
                    calc === 'calculation_type' ||
                    calc === 'ajh' ||
                    calc === 'message' ||
                    calc === 'total_length' ||
                    calc === 'total_ajh' ||
                    calc === 'total_price'
                  ) {
                    return null;
                  }
                  const getTitle = currentType.find(el => el.name === calc);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  let value = results[i][calc];
                  if (calc === 'black') {
                    value = value === 1 ? 'Black' : 'Clear';
                  }
                  if (calc === 'reinforce') {
                    value = value === 1 ? 'Yes' : 'No';
                  }
                  return (
                    <li key={`${Math.random()}`}>
                      <span>
                        {getTitle && `${String(getTitle.label)} - ${value}`}
                      </span>
                    </li>
                  );
                })}
            </ul>
            <ul>
              <li>
                <span>{`Total Length - ${results[i].total_length}`}</span>
              </li>
              <li>
                <span>{`Total AJH - ${results[i].total_ajh}`}</span>
              </li>
              <li>
                <span>{`Total Price - ${results[i].total_price}`}</span>
              </li>
            </ul>
          </div>
        );
      })
    );
  };

  return (
    <StyledCalculator>
      <HeaderWithSearch />
      <div className="main">
        <div className="sectionHeading">
          <div className="pageHeading">
            <h2>Calculator</h2>
          </div>
        </div>
        <div className="calcAndResults">
          <div className="col">
            {formData &&
              formData.map((item, key) => {
                return indexCaclculation !== key || actionType !== 'edit' ? (
                  <div key={Math.random()}>
                    <CollapsedCalcSection
                      calculation={item}
                      index={key}
                      editCalculation={editCalculation}
                      deleteCalculation={deleteCalculation}
                    />
                  </div>
                ) : (
                  <div key={indexCaclculation}>
                    <EditForm
                      handleResults={handleEditResults}
                      setFormType={setFormType}
                      indexCaclculation={indexCaclculation}
                      indexedData={formData}
                      setIndexCalculation={setIndexCalculation}
                      formType={formType}
                    />
                  </div>
                );
              })}
            <CalculationForm handleResults={handleResults} />
          </div>
          <div className="modalWindow">
            {isPopup && (
              <ModalWindow
                setIsModalShow={setShowingPopup}
                action={clearPage}
                text="Are you sure you want to clear the page?"
              />
            )}
            {indexCaclculation !== undefined && isDeleteModal && (
              <ModalWindow
                setIsModalShow={setDeleteModal}
                action={handleDeleteCalculation}
                text={`Are you sure you want to delete <Calculation #${indexCaclculation +
                  1}> and it's result?`}
              />
            )}
          </div>
          <div className="col">
            <div className="colSection sectionImportFile">
              <div className="rowHeading">
                <h3>Calculate with file:</h3>
              </div>
              <ImportCalculationForm
                createTxtFromUploadFile={createTxtFromUploadFile}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
              />
            </div>
            <div className="colSection sectionResults">
              <div className="rowHeading">
                <h3>Results:</h3>
              </div>
              <div className="resultsOfCalc">
                {!results ||
                  (results.length === 0 && (
                    <div className="resultsRow">
                      <p className="detailed">Calculation №:1</p>
                      <span>---</span>
                    </div>
                  ))}
                {renderResult()}
                <div className="resultRowAction">
                  {results && results.length > 0 && (
                    <>
                      <button
                        className="copyResults"
                        type="button"
                        onClick={downloadTxtFile}
                      >
                        <Copy />
                        Download file
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="colAction">
              <button
                className="deleteButton"
                type="button"
                onClick={() => setShowingPopup(true)}
              >
                <Delete />
                Clear All
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyledCalculator>
  );
};

export default Calculator;
