import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { getSessionID } from 'src/services/user';
import deleteUser from 'src/actions/deleteUser';
import { Edit, Delete, User, SortDown, SortUp } from 'src/assets/icons';
import ModalWindow from './ModalWindow';
import StyledDashboardUserList from './DashboardUserListStyles';
import { SortConfigTypes } from './useSortableData';

interface DashboardPropsTypes {
  userList: User[];
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
  requestSort: (key: string, key2?: string) => void;
  sortConfig: SortConfigTypes | null;
}

const DashboardUserList = (props: DashboardPropsTypes) => {
  const { userList, isLoading, setIsLoading, requestSort, sortConfig } = props;
  const [userName, setUserName] = useState('');
  const [isModalShow, setIsModalShow] = useState(false);
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const onModalOpen = (item: User) => {
    setIsModalShow(!isModalShow);
    setUserName(item.username);
  };
  const sessionID = getSessionID();
  const deletUser = () => {
    if (userName && setIsLoading) {
      setIsModalShow(false);
      setIsLoading(true);
      deleteUser(userName, sessionID, setIsLoading);
    }
  };
  if (isLoading) {
    return (
      <div className="loaderWrapper">
        <Loader
          type="TailSpin"
          color="#4277F8"
          height={150}
          width={150}
          className="loader"
        />
      </div>
    );
  }
  return (
    <StyledDashboardUserList>
      <div className="dashboardTableSection">
        {isModalShow && (
          <div className="modalWindow">
            <ModalWindow
              setIsModalShow={setIsModalShow}
              action={deletUser}
              text={`Are you sure you want to delete ${userName}?`}
            />
          </div>
        )}
        <table className="dashboardTableContainer">
          <thead>
            <tr>
              <th>
                <input
                  className="dashboardCheckbox"
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                />
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('name')}
                  className={getClassNamesFor('name')}
                >
                  <div className="sortIconsBlock">
                    <SortUp />
                    <SortDown />
                  </div>
                  Name
                  <span className="tooltip">Sort</span>
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('username')}
                  className={getClassNamesFor('userName')}
                >
                  <div className="sortIconsBlock">
                    <SortUp />
                    <SortDown />
                  </div>
                  Username
                  <span className="tooltip">Sort</span>
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('valid', 'from')}
                  className={getClassNamesFor('validFrom')}
                >
                  <div className="sortIconsBlock">
                    <SortUp />
                    <SortDown />
                  </div>
                  Start Date
                  <span className="tooltip">Sort</span>
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('valid', 'to')}
                  className={getClassNamesFor('validTo')}
                >
                  <div className="sortIconsBlock">
                    <SortUp />
                    <SortDown />
                  </div>
                  End Date
                  <span className="tooltip">Sort</span>
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('privileges', 'admin')}
                  className={getClassNamesFor('privileges')}
                >
                  <div className="sortIconsBlock">
                    <SortUp />
                    <SortDown />
                  </div>
                  Role
                  <span className="tooltip">Sort</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {userList.map((item: User) => {
              return (
                <tr key={item.username}>
                  <td>
                    <input
                      className="dashboardCheckbox"
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                    />
                  </td>
                  <td>
                    <Link className="accountUserLink" to="/">
                      <User />
                      {item.name}
                    </Link>
                  </td>
                  <td>
                    <Link className="accountUserLink" to="/">
                      {item.username}
                    </Link>
                  </td>
                  <td>{item.valid.from}</td>
                  <td>{item.valid.to}</td>
                  <td>{item.privileges.admin === 1 ? 'Admin' : 'User'}</td>
                  <td className="dashManageAccount">
                    <Link to={`/user/${item.username}`}>
                      <Edit />
                      <span className="tooltip">Edit</span>
                    </Link>
                    <button type="button" onClick={() => onModalOpen(item)}>
                      <Delete />
                      <span className="tooltip">Delete</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </StyledDashboardUserList>
  );
};

export default DashboardUserList;
