import React from 'react';
import { Link } from 'react-router-dom';
import StyledLogo from './logoStyles';

const Logo = () => {
  return (
    <StyledLogo>
      <Link to="/">Ajasto calculator</Link>
    </StyledLogo>
  );
};
export default Logo;
