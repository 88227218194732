import styled from 'styled-components';

const StyledLoginLayout = styled.div`
   {
    display: flex;
    justify-content: center;
  }
  .loginFormContainer {
    width: 60%;
  }
  .logoSection {
    margin-bottom: 15%;
  }

  .inputSection {
    margin-left: 18%;
  }

  .inputSection h1 {
    line-height: 1.36;
    color: ${props => props.theme.main.colors.colorBlackSecondary};
  }

  .inputSection p {
    line-height: 1.5;
    color: ${props => props.theme.main.colors.colorBlackSecondary};
  }

  .loginPageContainer {
    width: 40%;
  }

  .loginPageContainer img {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }

  @media screen and (max-width: 1070px) {
    .loginPageContainer {
      display: none;
    }

    .loginFormContainer {
      width: 100%;
    }

    .inputSection {
      margin: 0 auto;
    }
  }
`;
export default StyledLoginLayout;
