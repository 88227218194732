import React from 'react';
import StyledButton from './btnPrimaryStyles';

export interface ButtonPrimaryTypes {
  btnText: string;
  action?: (e: any) => void;
  type: string;
  disabled?: boolean;
  size?: string;
}

const ButtonPrimary: React.FC<ButtonPrimaryTypes> = (
  props: ButtonPrimaryTypes
) => {
  const { type, action, btnText, disabled, size } = props;
  return (
    <StyledButton type={type} onClick={action} disabled={disabled} size={size}>
      {btnText}
    </StyledButton>
  );
};
export default ButtonPrimary;
