export const arrayUnique = (array: string[]) => {
  const a = array.concat();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    // eslint-disable-next-line no-plusplus
    for (let j = i + 1; j < a.length; j++) {
      if (a[i] === a[j]) {
        // eslint-disable-next-line no-plusplus
        a.splice(j--, 1);
      }
    }
  }
  return a;
};
