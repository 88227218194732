import styled from 'styled-components';

const StyledModalWindow = styled.div`
  .delUserWrapper {
    flex-direction: column;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: white;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 5px;
  }

  .delUserHeader {
    text-align: center;
    padding: 5px;
  }

  .userName {
    color: red;
  }

  .delUserForm {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .delUserForm {
    padding: 35px 22px 2px;
  }

  .delUserForm button {
    height: unset;
    padding: 15px 40px;
  }

  .delUserWrapper {
    padding: 35px 38px 20px;
  }

  .delUserHeader {
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    line-height: 1.2;
    padding: 3px;
  }
`;
export default StyledModalWindow;
