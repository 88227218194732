import { history } from 'src/components/App/App';
import { getUserData, auth, setUserData } from 'src/services/user';
import { createAuthContext } from 'src/context/auth';
import Routes from 'src/config/routes';
import errMessage from 'src/helpers/errorMessage';

interface ResponseTypes {
  sessionID: string;
  message: string;
  terms_conditions: number;
}

const login = async (
  userName: string,
  password: string,
  callBlack: (e: boolean) => void,
  errorCallBack: (e: boolean) => void,
  callbackValidation: (e: boolean) => void,
  setIsShowing: (e: boolean) => void
) => {
  try {
    const response: ResponseTypes = await auth(
      userName,
      password,
      callBlack,
      errorCallBack,
      callbackValidation
    );
    history.location.state = {};
    if (response && response.message === 'Authentication failed!') {
      callbackValidation(true);
      callBlack(false);
    }
    if (response && response.message !== 'Authentication failed!') {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { sessionID, terms_conditions } = response;
      const { user } = await getUserData(userName, sessionID);
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (terms_conditions !== 1 && response.message === 'OK') {
        setIsShowing(true);
        createAuthContext({ userData: user, userPass: password });
      }
      const userData = {
        name: user.name,
        privileges: user.privileges,
        sessionID,
        userName,
      };

      setUserData(userData);
      callBlack(false);
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (terms_conditions === 1) {
        history.push(Routes.Dashboard);
      }
    }
  } catch (e) {
    callBlack(false);
    errMessage(e);
  }
};

export default login;
