import styled from 'styled-components';

const StyledLoginForm = styled.div`
  .wrapper {
    max-width: 432px;
  }

  form {
    margin-top: 45px;
  }

  .expiredError {
    color: red;
  }

  .termsWrapper {
    display: flex;
    flex-direction: column;
  }

  .termsHeader {
    text-align: center;
    padding: 25px 0 10px 0;
  }

  .termsForm {
    display: flex;
    justify-content: space-between;
    padding: 25px;
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  .inputLabel {
    color: ${props => props.theme.main.colors.colorInputLabel};
    font-family: 'RobotoMedium', sans-serif;
    margin-bottom: 10px;
    display: block;
  }

  form .formRow {
    margin-bottom: 15px;
  }

  .forgotPass {
    text-decoration: underline;
  }

  .formAction {
    margin-top: 25px;
  }

  .termsAndPass {
    display: flex;
    justify-content: space-between;
  }

  input {
    height: 50px;
    width: 100%;
    padding: 20px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    .wrapper form .termsAndPass {
      display: block;
      text-align: center;
      line-height: 1.5;
    }
    .forgotPass {
      padding-left: 10px;
    }
  }
`;
export default StyledLoginForm;
