import React, { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'src/components/Logo';
import Routes from 'src/config/routes';
import { signOutUser, getName } from 'src/services/user';
import { Edit, Logout, Search, Up, User } from 'src/assets/icons';
import StyledHeader from './styles';

type HeaderWithSearchTypes = {
  handleOnSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchTerm?: string;
  userName?: string;
};

const HeaderWithSearch = (props: HeaderWithSearchTypes) => {
  const { handleOnSearch, searchTerm } = props;
  const [isShowing, setIsShowing] = useState(false);
  const name = getName();

  return (
    <StyledHeader>
      <div className="header">
        <div>
          <Logo />
        </div>
        {handleOnSearch && (
          <div className="searchSection">
            <Search />
            <input
              type="search"
              className="mainSearch"
              placeholder="Search for user"
              value={searchTerm}
              onChange={handleOnSearch}
            />
          </div>
        )}
        <div className="profileSection">
          <div className="mainProfile">
            <button
              type="button"
              className="profileName"
              onClick={() => setIsShowing(!isShowing)}
            >
              <span className="profileImg">
                <User />
              </span>
              {name}
              <span className="dropdownIcon">
                <Up />
              </span>
            </button>
          </div>
          {isShowing && (
            <ul className="mainProfileOptions">
              <li>
                <Link className="" to={Routes.EditAccount}>
                  <Edit />
                  Edit account
                </Link>
              </li>
              <li>
                <Link to="/login" onClick={() => signOutUser()}>
                  <Logout />
                  Logout
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </StyledHeader>
  );
};

export default HeaderWithSearch;
