import { getUsersList, delUser } from 'src/services/user';
import errMessage from 'src/helpers/errorMessage';

const deleteUser = async (
  userName: string,
  sessionID: string,
  callBlack: (e: boolean) => void
) => {
  try {
    await delUser(userName, sessionID);
    await getUsersList(sessionID);
    callBlack(false);
  } catch (e) {
    errMessage(e);
    callBlack(false);
  }
};
export default deleteUser;
