import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Heading, Input, BtnPrimary } from 'src/components';
import forgotPassword from 'src/actions/forgotPassword';
import StyledForgotPasswordForm from './ForgotPasswordStyles';

interface ValuesTypes {
  userName: string;
}

const ForgotPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordMessage, setPassMessage] = useState('');

  const onSubmitForgotPassword = (values: ValuesTypes) => {
    setIsLoading(true);
    forgotPassword(values.userName, setIsLoading, setPassMessage);
  };
  const disabled = (values: ValuesTypes) => {
    return !values.userName;
  };
  return (
    <StyledForgotPasswordForm>
      <div className="wrapper">
        <Heading
          text="Enter your username to resset your password. New password will be send on
          your e-mail."
          heading="Forgot password?"
        />
        {passwordMessage === 'OK' && (
          <h2 className="passwordMessage">
            Your password has been successfully reset.
          </h2>
        )}
        <Form
          onSubmit={onSubmitForgotPassword}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="formRow">
                <div className="col-12">
                  <span className="inputLabel">Username</span>
                  <Input
                    type="text"
                    placeholder="Write username here"
                    name="userName"
                  />
                </div>
              </div>
              <div className="formAction">
                {isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="#4277F8"
                    height={50}
                    width={50}
                    className="loader"
                  />
                ) : (
                  <BtnPrimary
                    type="submit"
                    btnText="Send"
                    disabled={disabled(values)}
                  />
                )}
              </div>
              <div className="formRow">
                <div className="col-6">
                  <Link className="rememberLogin" to="/login">
                    Remember your password?
                  </Link>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </StyledForgotPasswordForm>
  );
};
export default ForgotPasswordForm;
