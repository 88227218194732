import styled from 'styled-components';

const handleButtonSize = (size: string) => {
  switch (size) {
    case 'tiny':
      return '40px';
    case 'small':
      return '140px';
    case 'medium':
      return '288px';
    case 'large':
      return '420px';
    default:
      return '100%';
  }
};

const StyledButton = styled.button<any>`
background-color: ${props =>
  props.disabled
    ? props.theme.main.colors.colorLightGrey
    : props.theme.main.colors.colorBlueMain};
;
  border: none;
  padding: ${props =>
    props.size === 'tiny' ? '15px 10px 30px 10px;' : '22px 40px'};
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  font-family: 'RobotoMedium', sans-serif;
  font-size: 14px;
  max-width: ${({ size }) => handleButtonSize(size)};
  height: ${props => (props.size === 'tiny' ? '20px' : undefined)};
  letter-spacing: 1px;
  line-height: 1.14;
  color: ${props => props.theme.main.colors.colorWhite};
  text-transform: uppercase;
  }

  :hover {
   background-color: ${props =>
     props.disabled ? props.theme.main.colors.colorLightGrey : '#2963F0'};

  }
  
  :active {
   background-color: #114CDA;
  }
`;
export default StyledButton;
