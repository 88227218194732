import { getSessionID } from 'src/services/user';
import errMessage from 'src/helpers/errorMessage';
import http from 'src/services/http';

export type CalculateType = {
  file_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any;
};

type CallBackType = (flag: boolean, result?: number[]) => void;

const calculateWithFile = async (
  request: CalculateType,
  callBack: CallBackType
) => {
  const data = new FormData();
  data.append('file', request.file);
  data.append('file_type', request.file_type);
  try {
    await callBack(true);
    const sessionID = await getSessionID();
    const response: number[] = await http(
      `upload/${sessionID}`,
      {
        method: 'POST',
        body: data,
      },
      true
    );
    if (response) {
      await callBack(false, response);
    }
  } catch (e) {
    callBack(false);
    errMessage(e);
  }
};
export default calculateWithFile;
