import React from 'react';

const Up = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-968.000000, -2507.000000)">
          <g
            id="Input/select/hover"
            transform="translate(768.000000, 2486.000000)"
          >
            <g id="Icon/Filled/Arrow/Down">
              <g transform="translate(194.000000, 13.000000)">
                <g id="Icon/Filled/Arrow/Up">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                  <path
                    d="M15.80994,11.4926089 L10.5075832,6.19034896 C10.3849461,6.06761505 10.2212363,6 10.0466771,6 C9.87211792,6 9.70840812,6.06761505 9.58577108,6.19034896 L9.19528932,6.58073386 C8.94120009,6.83511369 8.94120009,7.24855358 9.19528932,7.50254593 L13.6478083,11.9550649 L9.19034896,16.4125242 C9.06771192,16.5352581 9,16.698871 9,16.8733333 C9,17.0479894 9.06771192,17.2116023 9.19034896,17.3344331 L9.58083073,17.7247211 C9.70356464,17.8474551 9.86717756,17.9150701 10.0417368,17.9150701 C10.216296,17.9150701 10.3800058,17.8474551 10.5026428,17.7247211 L15.80994,12.4176177 C15.9328676,12.2944963 16.0003858,12.1301084 16,11.9553555 C16.0003858,11.7799245 15.9328676,11.6156334 15.80994,11.4926089 Z"
                    id="Path"
                    fill="#4277F8"
                    fillRule="nonzero"
                    transform="translate(12.500000, 11.957535) rotate(270.000000) translate(-12.500000, -11.957535) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Up;
