import React from 'react';

const Eye = () => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-431.000000, -3955.000000)">
          <g
            id="Icon/Filled/Eye"
            transform="translate(430.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M11.9657477,5 C16.9551402,5 21.2068692,8.10028037 22.9314953,12.4766355 C21.2068692,16.8529439 16.9551402,19.953271 11.9657477,19.953271 C6.98130841,19.953271 2.72462617,16.8529439 1,12.4766355 C2.72462617,8.10028037 6.98130841,5 11.9657477,5 Z M11.9657477,7.49219626 C9.21434579,7.49219626 6.98130841,9.72518692 6.98130841,12.4765888 C6.98130841,15.2279907 9.21434579,17.461028 11.9657477,17.461028 C14.7171495,17.461028 16.9501869,15.2280374 16.9501869,12.4766355 C16.9501869,9.72523364 14.7171495,7.49219626 11.9657477,7.49219626 Z M11.9657477,9.48598131 C13.6155607,9.48598131 14.9564019,10.8267757 14.9564019,12.4766355 C14.9564019,14.1264953 13.6156075,15.4672897 11.9657477,15.4672897 C10.3158879,15.4672897 8.97509346,14.1264953 8.97509346,12.4766355 C8.97509346,10.8267757 10.3158879,9.48598131 11.9657477,9.48598131 Z"
                id="Combined-Shape"
                fill="#B6B7C2"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Eye;
