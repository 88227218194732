import http from 'src/services/http';
import errMessage from 'src/helpers/errorMessage';

const { localStorage } = window;

export const setUserData = (userData: UserData) => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

export const getName = () => {
  const pars = localStorage.getItem('userData');
  if (pars) {
    const { name } = JSON.parse(pars);
    return name;
  }
  return null;
};

export const getUserName = () => {
  const pars = localStorage.getItem('userData');
  if (pars) {
    const { userName: userNameLocal } = JSON.parse(pars);
    return userNameLocal;
  }
  return null;
};

export const getSessionID = () => {
  const pars = localStorage.getItem('userData');
  if (pars) {
    const { sessionID } = JSON.parse(pars);
    return sessionID;
  }
  return null;
};

export const getUserPermission = () => {
  const pars = localStorage.getItem('userData');
  if (pars) {
    const { privileges } = JSON.parse(pars);
    return privileges;
  }
  return null;
};

export const isAdmin = () => {
  const role = localStorage.getItem('userData');
  if (role) {
    const { privileges } = JSON.parse(role);
    return privileges.admin === 1;
  }
  return false;
};

export const signOutUser = () => {
  localStorage.removeItem('session_id');
  localStorage.removeItem('userName');
  localStorage.removeItem('userData');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserData: any = async (userName: string, sessionID: string) => {
  try {
    const data = await http(`users/${sessionID}/${userName}`, {
      method: 'GET',
    });
    const {
      user: { _key, _id, _rev, password, ...user },
      ...message
    } = data;
    return await { user, message };
  } catch (e) {
    // eslint-disable-next-line no-console
    errMessage(e);
  }
  return null;
};

export const getUsersList = async (sessionID: string) => {
  try {
    return await http(`users/${sessionID}`, {
      method: 'GET',
    });
  } catch (e) {
    errMessage(e);
  }
  return null;
};

export const auth = async (
  userName: string,
  password: string,
  callback: (e: boolean) => void,
  errCallBack: (e: boolean) => void,
  callbackValidation: (e: boolean) => void
) => {
  try {
    return await http(`authenticate/`, {
      method: 'POST',
      body: {
        username: `${userName}`,
        password: `${password}`,
      },
    });
  } catch (e) {
    if (e.message === 'Authentication failed!') {
      callback(false);
      callbackValidation(true);
    } else {
      callback(false);
      errCallBack(true);
      errMessage(e);
    }
  }
  return null;
};

export const forgotPass = async (userName: string) => {
  try {
    return await http(`/forgot_password/${userName}`, {
      method: 'GET',
    });
  } catch (e) {
    errMessage(e);
  }
  return null;
};

export const delUser = async (username: string, sessionID: string) => {
  try {
    return await http(`users/${sessionID}/${username}`, {
      method: 'DELETE',
    });
  } catch (e) {
    errMessage(e);
  }
  return null;
};
