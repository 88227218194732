import React, { useState } from 'react';
import LoginLayout from 'src/components/LoginLayout';
import LoginForm from './LoginForm';
import TermsConditions from './TermsConditions';

const Login = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoginLayout>
      {isShowing ? (
        <TermsConditions
          setIsShowing={setIsShowing}
          setIsLoading={setIsLoading}
        />
      ) : (
        <LoginForm
          setIsShowing={setIsShowing}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
    </LoginLayout>
  );
};

export default Login;
