import React from 'react';
import { Field } from 'react-final-form';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';
import moment from 'moment';

type InputTypes = {
  placeholder?: string;
  name: string;
  id?: string;
  disabled?: boolean;
};

const DateInput: React.FC<InputTypes> = (props: InputTypes) => {
  const { name, placeholder } = props;
  const required = (data: string) => (data ? undefined : 'Required');
  const pattern = 'YYYY-MM-DD';
  return (
    <Field name={name} validate={required} type="text">
      {({ input: { onChange, ...restInput }, meta }) => (
        <>
          <IMaskInput
            {...restInput}
            mask={Date}
            pattern={pattern}
            format={(date: moment.MomentInputObject) =>
              moment(date).format(pattern)
            }
            parse={(str: moment.MomentInputObject) => moment(str, pattern)}
            onAccept={(value: string) => onChange(value)}
            blocks={{
              YYYY: {
                mask: IMask.MaskedRange,
                from: 2020,
                to: 2050,
              },
              MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
              },
              DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
              },
            }}
            placeholder={placeholder}
          />
          {meta.error && meta.touched && (
            <span className="errorInput">{meta.error}</span>
          )}
        </>
      )}
    </Field>
  );
};
export default DateInput;
