import styled from 'styled-components';

const StyledDashboardPagination = styled.div<any>`
   {
    margin-top: auto;
  }

  .paginationSection nav {
    display: flex;
    justify-content: center;
    padding: 60px 0;
  }

  .paginationSection ul {
    display: flex;
    justify-content: center;
    border-radius: 4px;
  }

  .paginationSection .prevPage,
  .paginationSection .nextPage {
    background-color: ${props => props.theme.main.colors.colorWhite};
    padding: 10px 12px;
  }

  .paginationSection .prevPage {
    margin-right: 10px;
  }

  .paginationSection .nextPage {
    margin-left: 10px;
  }

  .pageItem button {
    padding: 10px 0;
    color: black;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    background-color: ${props => props.theme.main.colors.colorWhite};
    line-height: 1.28;
    border-top: 1px solid ${props => props.theme.main.colors.colorBorder};
    border-bottom: 1px solid ${props => props.theme.main.colors.colorBorder};
    width: 39px;
  }

  .pageItem:first-child button {
    border-right: 0;
    border-left: 1px solid ${props => props.theme.main.colors.colorBorder};
    border-radius: 4px 0 0 4px;
  }

  .pageItem:last-child button {
    border-left: 0;
    border-right: 1px solid ${props => props.theme.main.colors.colorBorder};
    border-radius: 0 4px 4px 0;
  }

  .pageItem.active button {
    background-color: ${props => props.theme.main.colors.colorBlueMain};
    border-radius: 4px;
    border: 1px solid ${props => props.theme.main.colors.colorBlueMain};
    color: ${props => props.theme.main.colors.colorWhite};
  }

  .pageItem:first-child button:active,
  .pageItem:first-child button:hover {
    padding-left: 1px;
  }

  .pageItem:last-child button:active,
  .pageItem:last-child button:hover {
    padding-right: 1px;
  }

  .pageItem button:hover {
    border: 1px solid ${props => props.theme.main.colors.colorBlueMain};
    border-radius: 4px;
  }

  .pageItem:first-child button:hover {
    padding-left: 1px;
  }

  .pageItem:last-child button:hover {
    padding-right: 1px;
  }

  .prevPage,
  .nextPage {
    border: 1px solid ${props => props.theme.main.colors.colorBorder};
    border-radius: 4px;
    margin-right: 10px;
    width: 39px;
  }

  .nextPage {
    margin-left: 10px;
  }

  .prevPage svg {
    transform: rotate(-90deg);
  }

  .nextPage svg {
    transform: rotate(90deg);
  }

  .prevPage:hover,
  .nextPage:hover {
    border: 1px solid ${props => props.theme.main.colors.colorBlueMain};
  }
`;
export default StyledDashboardPagination;
