import React from 'react';

const Edit = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-377.000000, -3953.000000)">
          <g
            id="Icon/Filled/Edit"
            transform="translate(374.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M13.5710199,5.83708099 L17.0292044,9.31216908 L8.27558437,18.1086584 L4.81936049,14.6335703 L13.5710199,5.83708099 Z M19.6532976,4.99896942 L18.1110804,3.44919944 C17.5150667,2.85026685 16.5472636,2.85026685 15.9492249,3.44919944 L14.471935,4.9337241 L17.9301195,8.4088445 L19.6532976,6.67722744 C20.1155675,6.21266143 20.1155675,5.46350312 19.6532976,4.99896942 Z M3.00962331,19.5180864 C2.94668879,19.802711 3.20241252,20.0577491 3.48568216,19.9885309 L7.33924852,19.0496121 L3.88302464,15.574524 L3.00962331,19.5180864 Z"
                id="Shape"
                fill="#B6B7C2"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Edit;
