import styled from 'styled-components';

const StyledLogo = styled.div`
  a {
    white-space: nowrap;
    color: #4277f8;
    font-family: 'OpenSansBold', sans-serif;
    font-size: 16px;
    line-height: 1.375;
    text-transform: uppercase;
    text-decoration: none;
    padding: 27px 0 27px 70px;
    display: block;
  }

  a:hover {
    text-decoration: none;
  }
`;
export default StyledLogo;
