import { createContext, useContext } from 'react';

let context: any = {};

export const createAuthContext = (data: any) => {
  context = createContext(data);
};

export function useAuth() {
  return useContext(context);
}
