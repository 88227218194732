import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from 'src/config/routes';
import MainLayout from 'src/components/MainLayout/MainLayout';
import { isAdmin } from 'src/services/user';
import Calculator from 'src/pages/Calculator/Calculator';
import Dashboard from 'src/pages/Dashboard/Dashboard';
import UserInfo from 'src/pages/UserInfo';

const MainRoutes = () => {
  return (
    <MainLayout>
      {isAdmin() ? (
        <Switch>
          <Route path={Routes.Dashboard} component={Dashboard} />
          <Route path={Routes.User} component={UserInfo} />
          <Route path={Routes.EditAccount} component={UserInfo} />
          <Redirect to={Routes.Dashboard} />
        </Switch>
      ) : (
        <Switch>
          <Route path={Routes.Calculator} component={Calculator} />
          <Route path={Routes.EditAccount} component={UserInfo} />
          <Redirect to={Routes.Calculator} />
        </Switch>
      )}
    </MainLayout>
  );
};

export default MainRoutes;
