import React from 'react';

const Search = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI-Kit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-kit" transform="translate(-602.000000, -3954.000000)">
          <g
            id="Icon/Filled/Search"
            transform="translate(598.000000, 3950.000000)"
          >
            <g>
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M19.2033684,18.5647719 L15.397614,14.6065965 C16.3761404,13.4433684 16.9122807,11.9797895 16.9122807,10.4561404 C16.9122807,6.8962807 14.016,4 10.4561404,4 C6.8962807,4 4,6.8962807 4,10.4561404 C4,14.016 6.8962807,16.9122807 10.4561404,16.9122807 C11.7925614,16.9122807 13.0661053,16.509193 14.1549474,15.744 L17.989614,19.7322105 C18.1498947,19.8986667 18.3654737,19.9904561 18.5964912,19.9904561 C18.8151579,19.9904561 19.0225965,19.9070877 19.1800702,19.7555088 C19.5146667,19.4335439 19.5253333,18.8996491 19.2033684,18.5647719 Z M10.4561404,5.68421053 C13.0874386,5.68421053 15.2280702,7.82484211 15.2280702,10.4561404 C15.2280702,13.0874386 13.0874386,15.2280702 10.4561404,15.2280702 C7.82484211,15.2280702 5.68421053,13.0874386 5.68421053,10.4561404 C5.68421053,7.82484211 7.82484211,5.68421053 10.4561404,5.68421053 Z"
                id="Shape"
                fill="#939393"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Search;
