import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Loader from 'react-loader-spinner';

import { SelectComponent, Input } from 'src/components';
import {
  calculationTypeConfig,
  inputsConfig,
  SizeInput,
  RadioInput,
  RadioValuesConfig,
} from 'src/config/calculationConfig';
import calculate, {
  CalculateType,
  CalculateResponseType,
} from 'src/actions/calculate';

export type FormTypes = {
  calculation_type: { value: string; label: string };
  size_mm?: string;
  size_dn?: string;
  thickness?: string;
  size_b?: string;
  thickness_b?: string;
  size_s?: string;
  thickness_s?: string;
  reinforce?: number;
  black?: string;
  dn1?: string;
  dn2?: string;
};

type CalculationFormType = {
  handleResults: (
    result: CalculateType & CalculateResponseType,
    form?: CalculateType
  ) => void;
};

const CalculationForm = (props: CalculationFormType) => {
  const { handleResults } = props;
  const [calculationType, setCalculationType] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const prepareSubmitData = (values: FormTypes): CalculateType => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { calculation_type } = values;
    return {
      ...values,
      black: Number(values.black),
      // eslint-disable-next-line @typescript-eslint/camelcase
      calculation_type: calculation_type.value,
    };
  };

  const resultCallback = (
    flag: boolean,
    data?: CalculateType & CalculateResponseType,
    formData?: CalculateType
  ) => {
    setLoading(flag);
    if (data) {
      handleResults(data, formData);
    }
    setCalculationType(undefined);
  };

  const handleOnSubmit = (values: FormTypes) => {
    calculate(prepareSubmitData(values), resultCallback);
  };

  const renderTextInput = (item: SizeInput, index: number) => {
    return (
      <div className="formRow" key={index}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          {item.label}
          <Input type="text" name={item.name} />
        </label>
      </div>
    );
  };

  const renderRadioInput = (item: RadioInput, index: number) => {
    return (
      <div className="rowRadio" key={index}>
        <p>{item.label}</p>
        <div className="selectType">
          {'values' in item
            ? item.values.map((radio: RadioValuesConfig) => {
                return (
                  <div
                    className="selectTypeRadio"
                    key={`${index}_${radio.title}`}
                  >
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="" className="inputLabel">
                      <Input
                        type="radio"
                        name={item.name}
                        id=""
                        value={radio.value}
                      />
                      <span className="checkmark" />
                      {radio.label}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };

  const renderInput = (item: SizeInput | RadioInput, index: number) => {
    switch (item.type) {
      case 'text':
        return renderTextInput(item as SizeInput, index);
      case 'radio':
        return renderRadioInput(item as RadioInput, index);
      default:
        return null;
    }
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      validateOnBlur
      // adding subscription just for fox react 16.13 perform warning issue
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={(...args) => {
            handleSubmit(...args);
            form.reset();
          }}
          className="colSection calcSection"
        >
          <div className="rowHeading">
            <h3>Calculation №:</h3>
          </div>
          <SelectComponent
            name="calculation_type"
            options={calculationTypeConfig}
            label="Calculation type"
            parentOnChange={setCalculationType}
          />
          {calculationType &&
            inputsConfig[calculationType].map(
              (item: SizeInput | RadioInput, index: number) => {
                return renderInput(item, index);
              }
            )}
          <div className="rowActionButtons">
            <div className="submitButton">
              {loading ? (
                <Loader
                  type="TailSpin"
                  color="#4277F8"
                  height={50}
                  width={50}
                  className="loader"
                />
              ) : (
                <button type="submit">Calculate</button>
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default CalculationForm;
