import styled from 'styled-components';

const StyledHeader = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #ffffff;
    box-shadow: 0 13px 13px -5px rgba(50, 54, 64, 0.08);
    padding: 0 5%;
  }

  .searchSection {
    max-width: 550px;
    width: 100%;
    margin: 0px 20px;
    position: relative;
    display: flex;
  }

  .searchSection svg {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 5;
  }

  .mainSearch {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 45px;
    padding: 0 50px;
    margin: 15px auto;
  }

  .profileSection {
    align-self: end;
    padding-top: 15px;
    margin-right: 70px;
    position: relative;
  }

  .profileImg {
    margin-right: 10px;
  }

  .profileName .profileImg svg {
    width: 40px;
    height: 40px;
    transform: rotate(0deg);
    margin-left: 0;
  }

  .profileName {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: none;
    font-family: ${props =>
      props.theme.main.fonts.fontFamily.fontRobotoRegular};
    font-size: 14px;
    line-height: 1.28;
    color: #000000;
    padding-left: 0;
    text-transform: none;
    white-space: nowrap;
  }

  .dropdownIcon {
    margin-left: 15px;
    margin-top: 2px;
    transform: rotate(180deg);
  }

  //.dropdownIcon {
  //  transform: rotate(0deg);
  //}

  .mainProfileOptions {
    position: absolute;
    top: 85px;
    right: 0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.02);
    max-width: 173px;
    width: 100%;
    min-width: 148px;
  }

  .mainProfileOptions li {
    padding-top: 20px;
    width: 100%;
  }

  .mainProfileOptions li:last-child {
    padding-bottom: 20px;
  }

  .mainProfileOptions a {
    position: relative;
    font-family: 'OpenSansRegular', sans-serif;
    font-size: 14px;
    line-height: 1.71;
    color: #000000;
    text-align: left;
    padding-left: 13px;
    text-decoration: none;
    outline: none;
    white-space: normal;
  }

  .mainProfileOptions svg {
    margin: 0 13px 0 6px;
    vertical-align: middle;
  }

  .mainProfileOptions a:hover {
    color: ${props => props.theme.main.colors.colorBlueMain};
  }

  .mainProfileOptions a:hover svg path {
    fill: ${props => props.theme.main.colors.colorBlueMain};
  }

  @media screen and (max-width: 1700px) {
    .header {
      padding: unset;
    }
  }
`;
export default StyledHeader;
