import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import UserInfo from './UserInfo';

import ROUTES from './routes';

const UserRoutes = () => (
  <Switch>
    <Route exact path={ROUTES.accountEdit} component={UserInfo} />
    <Route exact path={ROUTES.userNew} component={UserInfo} />
    <Route exact path={ROUTES.userEdit} component={UserInfo} />
    <Redirect to={ROUTES.redirect} />
  </Switch>
);

export default UserRoutes;
