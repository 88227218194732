import dotenv from 'dotenv';
import { get } from 'lodash';
import { history } from 'src/components/App/App';
import { signOutUser } from 'src/services/user';
import Routes from 'src/config/routes';
import 'url-search-params-polyfill';

dotenv.config();

interface Options {
  [key: string]: any;
}

interface GlobalOptions {
  headers: {
    [key: string]: any;
  };
  mode: string;
}

/**
 * default headers
 * enabled cors
 */
const globalOptions = (): GlobalOptions => {
  return {
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    mode: 'cors',
  };
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async (response: Response) => {
  let json;

  try {
    json = await response.json();
    // eslint-disable-next-line default-case
    switch (true) {
      case response.status >= 200 && response.status < 300:
        return json;
      case response.status === 511:
        signOutUser();
        history.push(Routes.Login, { expired: 'true' });
        break;
      case response.status === 401:
        history.push(Routes.Login);
        return { ...json, status: response.status };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e); // todo create ErrorHandler
  }

  throw json;
};

const prepareBody = (options: Options) => {
  const searchParams = new URLSearchParams();
  Object.keys(options).forEach(item => {
    searchParams.set(item, options[item]);
  });
  return searchParams;
};

/**
 * @param url string
 * @param options object
 * @returns {Promise<T>}
 */
const http = async <T>(url: string, options: Options, typeFile?: boolean) => {
  const prepareOptions =
    !typeFile &&
    options.method.toLowerCase() === 'post' &&
    options.method.toLowerCase() !== 'get' &&
    options.method.toLowerCase() !== 'delete'
      ? { ...options, body: prepareBody(options.body) }
      : { ...options };
  const newOptions = !typeFile
    ? { ...globalOptions(), ...prepareOptions }
    : { ...prepareOptions };
  const urlParams = new URLSearchParams(get(options, 'params', {}));
  const newUrl = `${process.env.REACT_APP_API_URL}/${url}${
    urlParams.toString() ? '?' : ''
  }${urlParams.toString()}`;
  const response = await fetch(
    newUrl,
    newOptions as Options & GlobalOptions /* todo */
  );
  return checkStatus(response);
};

export default http;
