export default {
  all: {
    mobile: {},
  },
  main: {
    colors: {
      colorBlackMain: '#000000',
      colorBlackSecondary: '#2A2F34',
      colorBlueMain: '#4277F8',
      colorLightGrey: '#B6B7C2',
      colorGrey: '#9D9D9D',
      colorWhite: '#FFFFFF',
      colorTitleTable: '#909090',
      colorInputLabel: '#515151',
      colorBorder: '#E1E1E1',
      colorBorderInputActive: '#66727F',
      colorError: '#E75348',
      colorBorderCheckbox: '#B8C3D6',
      colorActivePage: '#273748',
      colorBreadCrumbs: '#828282',
    },
    fonts: {
      fontFamily: {
        fontRobotoRegular: `'RobotoRegular', sans-serif`,
        fontRobotoMedium: `'RobotoMedium', sans-serif`,
        fontRobotoBold: `'RobotoBold', sans-serif`,
        fontOpenSansSemibold: `'OpenSansSemibold', sans-serif`,
      },
      fontSize: {
        fontSizeH1: '36px',
        fontSizeH2: '24px',
        fontSizeH3: '20px',
        fontSizeP: '16px',
        fontSizeMain: '14px',
        fontSizeLink: '12px',
      },
    },
  },
};
